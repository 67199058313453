<template>
  <div
    v-if="
      data.tasks &&
      data.tasks.length > 0 &&
      data.tasks.some((task) => task.tasks.length)
    "
    class="tab w-full border-b border-orange-600 overflow-hidden"
  >
    <div
      class="flex justify-between items-center cursor-pointer text-orange-600 hover:text-orange-400"
      @click="onToggle"
    >
      <span class="text-2xl font-semibold">{{ data.title }}</span>
      <div
        class="ml-2 w-3 h-3 rotate fill-current transition-duration-250 arrow justify-end"
        :class="{ 'rotate-90': toggle, '-rotate-90': !toggle }"
      >
        <vc-icon :icon="arrowIcon" />
      </div>
    </div>
    <div
      class="tab-content overflow-hidden px-2"
      :class="{ 'max-h-0': !toggle }"
    >
      <!-- <vc-advice v-for="(advice,index) in data.advices" :key="index" :data="advice"></vc-advice> -->
      <!-- <AdviceRemark :data="data" :note="data.note" /> -->
      <!-- <AdviceAverage :data="data" /> -->
      <AdviceTask :data="data" :content="data.content" />
      <!-- <div v-if="data.subsections">
        <vc-subsection v-for="(advice,index2) in data.subsections" :key="index2" :data="advice"></vc-subsection>
      </div>-->
    </div>
  </div>
</template>

<script>
// import Advice from "@/components/tabs/Advice.vue";
// import AdviceRemark from "@/components/tabs/AdviceRemark.vue";
import { arrowIcon } from "@/assets/data/icons.js";
// import AdviceAverage from "@/components/tabs/AdviceAverage.vue";
// import Subsection from "@/components/tabs/Subsection.vue";
import Icon from "@/components/icon/Icon.vue";
import AdviceTask from "@/components/tabs/AdviceTask.vue";

export default {
  components: {
    vcIcon: Icon,
    AdviceTask,
  },
  props: {
    data: Object,
    toggled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name,
      arrowIcon,
      toggle: this.toggled,
    };
  },
  computed: {
    maxHeight() {
      return this.toggle ? "max-h-1Griton " : "max-h-0";
    },
  },
  methods: {
    onToggle() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style lang="scss" scoped></style>
