<template>
  <div class="wrapper ml-20">
    <vc-header
      :iconName="name"
      :displayName="$t('dashboard.dashboard')"
      :email="getUserEmail"
    ></vc-header>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  data() {
    return {
      name: "dashboard",
    };
  },
  components: {
    "vc-header": Header,
  },
};
</script>

<style></style>
