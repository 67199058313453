import axios from "../../axios-auth";

const TASKS_ROLE = 5;
const TESTS_ROLE = 4;
export default {
  namespaced: true,
  state: {
    users: null,
  },
  getters: {
    getTasksUsers: (state) => {
      return state.users?.filter((user) => user.roles.includes(TASKS_ROLE));
    },
    getTestsUsers: (state) => {
      return state.users?.filter((user) => user.roles.includes(TESTS_ROLE));
    },
    getUsers: (state) => {
      return state.users;
    },
  },
  actions: {
    setUsers: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios
          .get("/users")
          .then((users) => {
            commit("setUsers", users.data);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    newUser: ({ dispatch }, user) => {
      return new Promise((resolve, reject) => {
        axios
          .post("/users/", user)
          .then(() => {
            dispatch("setUsers");
            resolve();
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    updateUser: ({ dispatch }, user) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${user.id}`, user)
          .then(() => {
            dispatch("setUsers");
            resolve();
          })
          .catch((error) => reject(error.response.data));
      });
    },
    deleteUser: ({ dispatch }, id) => {
      axios.delete(`/users/${id}`).then(() => {
        dispatch("setUsers");
      });
    },
  },
  mutations: {
    setUsers: (state, users) => {
      state.users = users;
    },
  },
};
