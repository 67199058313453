<template>
  <div>
    <div
      ref="boxTask"
      class="w-full my-8 flex-col md:px-4"
      :class="[cajaActiva, opacidad]"
    >
      <div class="relative">
        <span
          class="block absolute shadow w-13 h-13 bg-orange-400 z-50 rounded-full -top-4 py-3 text-white fill-current transition-duration-500"
        >
          <vc-icon :icon="tareasIcon" />
        </span>
        <span
          class="block absolute w-16 h-16 bg-gray-200 z-40 rounded-full -top-6 -left-2"
        />

        <div class="bg-white pt-12 pb-8 px-10 rounded h-full relative shadow">
          <div class="absolute bg-gray-200 z-10 w-4 h-24 -top-8 -left-4" />
          <div class="absolute bg-gray-200 z-10 w-20 h-8 -top-8 -left-4" />
          <div
            class="absolute border-6 border-orange-400 rounded-full w-20 h-20 bg-gray-200 -top-8 -left-4"
          />
          <div class="w-full h-full">
            <!--  -->
            <button
              v-if="showButtons && (!enabled || !!task.new)"
              v-tooltip="$t('tareas.eliminarTarea')"
              class="absolute w-6 top-0 right-0 m-2 focus:outline-none"
              @click="deleteTask"
            >
              <span
                class="rounded-full text-orange-400 fill-current hover:text-orange-600"
              >
                <vc-icon :icon="plusIcon" class="rotate-45" />
              </span>
            </button>
            <!-- Campos -->
            <!-- Nombre -->
            <div v-if="task.new" class="mb-4 flex">
              <label for="nombre">{{ $t("common.nombre") | firstCapital }}:</label>
              <div class="flex-grow pl-2">
                <input
                  v-model="task.name"
                  type="text"
                  class="form-control w-full px-1 block border-b text-sm placeholder-orange-300 focus:placeholder-orange-500 border-orange-400 focus:outline-none"
                  :placeholder="$t('common.nombre') | firstCapital"
                  @input="$v.task.name.$touch()"
                />
              </div>
            </div>
            <span
              v-else
              class="text-orange-600 -top-6 left-0 font-bold mb-4 truncate block w-full"
            >{{ task.name }}</span>
            <div v-if="$v.task.name.$error && task.name" class="error text-xs">
              {{
                $t("error.errorCampoLargo", {
                  campo: $t("common.nombre"),
                  length: $v.task.name.$params.maxLength.max,
                })
              }}
            </div>
            <div v-if="$v.task.name.$error && !task.name" class="error text-xs">
              {{
                $t("error.errorCampoObligatorio", {
                  campo: $t("common.nombre"),
                })
              }}
            </div>
            <div class="flex mb-4 justify-between">
              <div class="flex flex-col w-2/5 justify-start">
                <label>{{ $t("tareas.asignadaA") }}:&nbsp;</label>
                <SelectCustom
                  v-if="usersArray.length"
                  v-model="task.assignedTo"
                  :options="usersArray"
                  :stylewrap="styleSelectCustom"
                  :styleoption="styleOptionSelectCustom"
                  styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b h-auto"
                  styleicon="border-orange-400"
                  :disabled="!enabled && !task.new"
                />
                <div v-else class="text-sm pl-1 h-6">Cargando</div>
              </div>
            </div>
            <!-- Estado/Prioridad -->
            <div class="flex mb-4 justify-between">
              <div class="flex flex-col w-2/5 justify-start">
                <label>{{ $t("common.estado") }}:&nbsp;</label>
                <SelectCustom
                  v-model="task.state"
                  :options="listaEstado"
                  :stylewrap="styleSelectCustom"
                  :styleoption="styleOptionSelectCustom"
                  styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b h-auto"
                  styleicon="border-orange-400"
                  :disabled="!enabled && !task.new"
                  :placeholder="listaEstado[0].name"
                />
              </div>
              <div class="flex flex-col w-2/5 justify-end">
                <label>{{ $t("common.prioridad") }}:&nbsp;</label>
                <SelectCustom
                  v-model="task.priority"
                  :options="listaPrioridad"
                  :stylewrap="styleSelectCustom"
                  :styleoption="styleOptionSelectCustom"
                  styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b h-auto"
                  styleicon="border-orange-400"
                  :disabled="!enabled && !task.new"
                  :placeholder="listaPrioridad[0].name"
                />
              </div>
            </div>
            <!-- Descripcion -->
            <div class="flex flex-col mb-4">
              <label>{{ $t("common.descripcion") | firstCapital }}:</label>
              <div class="h-24 mt-2">
                <div
                  v-bar
                  class="p-1 pr-0 w-full h-full block border text-sm placeholder-orange-300 border-orange-400 vb rounded focus:outline-none"
                >
                  <textarea
                    v-model="task.description"
                    :placeholder="$t('common.descripcion') | firstCapital"
                    class="h-full w-full focus:outline-none resize-none"
                    style="width: calc(100% + 17px)"
                    :disabled="!enabled && !task.new"
                    @input="$v.task.description.$touch()"
                  />
                </div>
              </div>
            </div>
            <div v-if="$v.task.description.$invalid" class="error text-xs">
              {{
                $t("error.errorCampoLargo", {
                  campo: $t("common.descripcion"),
                  length: $v.task.description.$params.maxLength.max,
                })
              }}
            </div>
            <!-- Relacion -->
            <div class="flex flex-col mb-4">
              <div class>
                <div class="flex justify-between">
                  <div class="w-2/5">
                    <label>Test:</label>
                    <div v-if="listaTests.length" class="h-6">
                      <SelectCustom
                        v-model="task.idtest"
                        :options="listaTests"
                        :stylewrap="styleSelectCustom"
                        :styleoption="styleOptionSelectCustom"
                        styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b h-auto"
                        styleicon="border-orange-400"
                        :disabled="!enabled && !task.new"
                        @input="onClickTest(task.idtest)"
                      />
                    </div>
                    <div v-else class="text-sm pl-1 h-6">Cargando</div>
                  </div>
                  <div class="w-2/5">
                    <label>{{ $t("common.seccion") }}:</label>
                    <div v-if="listaSections.length" class="h-6">
                      <SelectCustom
                        v-model="task.idsection"
                        :options="listaSections"
                        :stylewrap="styleSelectCustom"
                        :styleoption="styleOptionSelectCustom"
                        styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b h-auto"
                        styleicon="border-orange-400"
                        :disabled="!enabled && !task.new"
                        @input="onClickSection(task.idsection)"
                      />
                    </div>
                    <div v-else class="text-sm pl-1 h-6">Cargando</div>
                  </div>
                </div>
                <div class="mt-2">
                  <!-- :class="[{'text-orange-400': enabled}]" -->
                  <label>{{ $t("common.pregunta") }}:</label>
                  <div v-if="questionList.length" class="h-6">
                    <SelectCustom
                      v-model="task.idquestion"
                      :options="listaPreguntas"
                      :stylewrap="styleSelectCustom"
                      :styleoption="styleOptionSelectCustom"
                      styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b h-64"
                      styleicon="border-orange-400"
                      :disabled="!enabled && !task.new"
                    />
                  </div>
                  <div v-else class="text-sm pl-1 h-6">Cargando</div>
                </div>
              </div>
            </div>

            <!-- Boton Editar / Guardar -->
            <!-- {{$v.task.name.$invalid}}
            {{$v.task.description.$invalid}}-->
            <div v-if="showButtons" class="flex w-full justify-end mt-6">
              <div
                class="overflow-hidden transition-duration-500 whitespace-nowrap"
                :class="divExpanded"
              >
                <vc-btn-simple
                  :name="editarGuardar"
                  style-wrap="text-white bg-orange-600 py-1 uppercase text-xs font-semibold rounded whitespace-nowrap w-20 border-2 border-orange-600"
                  disable-style="text-white bg bg-gray-500 py-1 uppercase text-xs font-semibold rounded whitespace-nowrap w-20  border-2 border-gray-500"
                  :disabled="!habilitado"
                  :invalid="
                    $v.task.name.$error ||
                      $v.task.description.$error ||
                      !task.name
                  "
                  @click="updateTask"
                />

                <vc-btn-simple
                  :name="$t('common.cancelar')"
                  style-wrap="text-orange-600 bg-white uppercase text-xs font-semibold rounded whitespace-nowrap border-orange-600 ml-2 w-20 py-1 border-2"
                  @click="cancelTask"
                />
              </div>
            </div>
          </div>
        </div>
        <p class="text-gray-600 text-center text-sm mt-1">
          {{ $t("tareas.creadaPor") }}:
          <span class="font-semibold">{{ creadaPor }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { maxLength, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

import { plusIcon, tareasIcon } from "@/assets/data/icons.js";
import BtnSimple from "@/components/BtnSimple.vue";
import SelectCustom from "@/components/SelectCustom.vue";

import Icon from "./icon/Icon.vue";

export default {
  name: "BoxTarea",
  props: {
    showButtons: {
      type: Boolean,
      default: false,
    },
    editable: {
      default: true,
    },
    task: Object,
    usersArray: {
      type: Array,
      default: () => [],
    },
    usersArrayAll: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      getSections: "tests/getTestSections",
      getTests: "tests/getTests",
      getQuestions: "tests/getTest",
      getPriorities: "tasks/getPriorities",
      getStates: "tasks/getStates",
      getUserRoles: "auth/getRoles",
    }),
    creadaPor() {
      return this.usersArrayAll.length
        ? this.usersArrayAll.find((user) => user.value === this.task.createdBy)
            .name
        : "";
    },
    cajaActiva() {
      return this.enabled || this.task.new ? "box-enable" : "box-disable";
    },
    opacidad() {
      return this.visible ? "opacity-100" : "opacity-0";
    },
    editarGuardar() {
      return this.enabled || this.task.new
        ? this.$t("common.guardar")
        : this.$t("common.editar");
    },
    divExpanded() {
      return this.enabled || this.task.new ? "w-45" : "w-20";
    },
    habilitado() {
      if (
        this.task.new ||
        (this.enabled && !this.editable) ||
        (!this.enabled && this.editable)
      ) {
        return true;
      } else {
        return false;
      }
    },
    styleSelectCustom() {
      return this.enabled || this.task.new
        ? "border-b border-orange-400 text-sm pl-1 truncate h-6"
        : "border-b border-gray text-sm pl-1 truncate h-6";
    },
    styleOptionSelectCustom() {
      return "p-1 text-sm  text-orange-400 text-left hover:text-white hover:bg-orange-600 ";
    },
    listaTests() {
      return this.testList.length <= 0 ? [] : this.testList;
    },
    listaSections() {
      return this.sectionList.length <= 0 ? [] : this.sectionList;
    },
    listaPreguntas() {
      return this.questionList.length <= 0 ? [] : this.questionList;
    },
    listaEstado() {
      return this.stateList.length <= 0 ? [] : this.stateList;
    },
    listaPrioridad() {
      return this.priorityList.length <= 0 ? [] : this.priorityList;
    },
  },
  validations: {
    task: {
      name: {
        maxLength: maxLength(100),
        required,
      },
      description: {
        maxLength: maxLength(1024),
      },
    },
  },
  methods: {
    ...mapActions({
      setTests: "tests/setCompanyTests",
      setSections: "tests/setTestSectionsBasic",
      setQuestions: "tests/setTestBasic",
    }),
    deleteTask() {
      this.onEdition = false;

      this.$emit("deleteTask");
    },
    cancelTask() {
      this.onClickSection(this.task.idsection);
      this.enabled = !this.enabled;
      this.onEdition = false;
      this.$emit("cancelTask");
    },
    updateTask() {
      if (this.enabled || this.task.new) {
        this.$emit("updateTask");
        this.onEdition = false;
      } else {
        this.$emit("editingTask");
        this.onEdition = true;
      }
      this.enabled = !this.enabled;
    },
    onClickTest(idtest) {
      this.setSections(idtest).then((sections) => {
        this.task.idsection = sections[0].id;
        this.setSectionsValues(sections);
        this.onClickSection(this.task.idsection);
      });
    },
    onClickSection(idsection) {
      const params = {
        idTest: this.task.idtest,
        idSection: idsection,
      };
      this.questionList = [];
      this.setQuestions(params).then((questions) => {
        this.task.idquestion = questions.questions[0].id;
        this.setQuestionValues(questions);
      });
    },
    setTestsValues(tests) {
      this.testList = tests.map((test) => ({
        name: test.name,
        value: test.id,
      }));
    },
    setSectionsValues(sections) {
      this.sectionList = sections.map((section) => ({
        name: section.name,
        value: section.id,
      }));
    },
    setQuestionValues(questions) {
      this.questionList = questions.questions.map((question) => ({
        name: question.question,
        value: question.id,
      }));
    },
  },
  data() {
    return {
      asignadaA: null,
      visible: false,
      plusIcon,
      tareasIcon,
      enabled: false,
      onEdition: false,
      testList: [],
      sectionList: [],
      questionList: [],
      stateList: [
        {
          name: this.$t("common.pendiente"),
          value: 0,
        },
        {
          name: this.$t("common.completado"),
          value: 1,
        },
      ],
      priorityList: [
        {
          name: this.$t("common.baja"),
          value: 0,
        },
        {
          name: this.$t("common.media"),
          value: 1,
        },
        {
          name: this.$t("common.alta"),
          value: 2,
        },
      ],
    };
  },
  filters: {
    firstCapital(val) {
      return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
    },
  },
  created() {
    this.$nextTick(function () {
      this.visible = true;
    });
    //Tests
    this.setTests().then((tests) => this.setTestsValues(tests));

    //Secciones
    this.setSections(this.task.idtest).then((sections) =>
      this.setSectionsValues(sections)
    );

    // //Preguntas
    const params = {
      idTest: this.task.idtest,
      idSection: this.task.idsection,
    };
    this.setQuestions(params).then((questions) =>
      this.setQuestionValues(questions)
    );
  },
  components: {
    "vc-icon": Icon,
    vcBtnSimple: BtnSimple,
    SelectCustom,
  },
};
</script>
