<template>
  <div class="w-full bg-csirt">
    <div class="container mx-auto h-full flex justify-center items-center">
      <div class="w-3/5 flex relative">
        <div class="form-background-rotate" />
        <div class="bg-white shadow-md z-20 px-10 py-5 m-auto w-3/4">
          <h2 class="text-orange-600 text-3xl font-bold leading-normal">
            {{ $t("password.restablecer") }}
          </h2>
          <vc-input
            v-model="passwordValue"
            :form-field="password"
            style-wrap="form-control h-8 w-full w-full mt-2 py-2 px-4 border border-black rounded text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none"
            @input="$v.passwordValue.$touch()"
          />
          <div v-if="$v.passwordValue.$error" class="error text-xs">
            *{{ $t("password.errorPassSize1") }}
            {{ $v.passwordValue.$params.minLength.min }} {{ $t("password.errorPassSize2") }}.
          </div>
          <vc-input
            v-model="passwordConfirmValue"
            :form-field="passwordConfirm"
            style-wrap="form-control h-8 w-full w-full mt-2 py-2 px-4 border border-black rounded text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none"
            @input="$v.passwordConfirmValue.$touch()"
          />
          <div
            v-if="
              $v.passwordConfirmValue.$dirty && $v.passwordConfirmValue.$error
            "
            class="error text-xs"
          >
            *{{ $t("password.diferentes") }}
          </div>
          <div class="flex flex-wrap py-4">
            <button
              class="btn-square w-1/5"
              :disabled="
                loading ||
                  $v.passwordValue.$invalid ||
                  $v.passwordConfirmValue.$invalid ||
                  peticionToken
              "
              @click.prevent="onChangePassword"
            >
              {{ loadingText }}
            </button>
            <div v-if="peticionMsg" class="w-4/5 error text-md font-bold mt-0">
              {{ peticionMsg }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import { arrowIcon, marcaCsirtIcon } from "@/assets/data/icons.js";
import StartInput from "@/components/StartInput.vue";

export default {
  data() {
    return {
      loading: false,
      peticionMsg: null,
      peticionToken: false,
      arrowIcon,
      marcaCsirtIcon,
      password: {
        name: "passwordconfirm",
        type: "password",
        displayName: `${this.$t("password.nuevaPass")}:`,
        placeholder: this.$t("password.nuevaPass"),
        id: "password",
      },
      passwordConfirm: {
        name: "passwordconfirm",
        type: "password",
        displayName: `${this.$t("password.repetirPass")}:`,
        placeholder: this.$t("password.repetirPass"),
        id: "passwordconfirm",
      },
      passwordValue: "",
      passwordConfirmValue: "",
    };
  },
  computed: {
    loadingText() {
      return this.loading ? "Cargando..." : "Enviar";
    },
  },
  validations: {
    passwordValue: {
      required,
      minLength: minLength(8),
    },
    passwordConfirmValue: {
      sameAsPassword: sameAs("passwordValue"),
    },
  },
  methods: {
    ...mapActions({
      changePassword: "auth/changePassword",
    }),
    onChangePassword() {
      const payload = {
        password: this.passwordValue,
        token: this.$route.query.token,
      };
      this.changePassword(payload)
        .then(() => {
          this.peticionMsg =
            `*${this.$t("password.recuperarPassOk")}`;
          this.peticionToken = true;
          setTimeout(() => this.$router.push({ name: "login" }), 5000);
        })
        .catch(() => {
          this.peticionMsg =
          `*${this.$t("password.recuperarPassTokenInvalido")}`;
          this.peticionToken = true;
          setTimeout(() => this.$router.push({ name: "login" }), 5000);
        });
    },
  },
  components: {
    vcInput: StartInput,
  },
};
</script>

<style></style>
