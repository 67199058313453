import axios from "axios";

// import axios from "./axios-basic";
import config from "./config";
import i18n from "./i18n";
import store from "./store/store";

const axiosAuth = axios.create();

axiosAuth.defaults.baseURL = config.URL_SERVER;
i18n.locale = window.localStorage.getItem("lang") || i18n.locale;
// const userId = localStorage.getItem("userId");
const token = localStorage.getItem(`token`);
axiosAuth.defaults.headers.common["x-access-token"] = token;
axiosAuth.defaults.headers.common["lang"] = i18n.locale;
axiosAuth.interceptors.request.use(
  (config) => {
    const expDate = store.getters["auth/getExpirationDate"];
    const authenticated = store.getters["auth/isAuthenticated"];
    const timeLeft = expDate - new Date().valueOf();
    if (!authenticated || timeLeft <= 0) {
      store.dispatch("auth/logout");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let lock = false;
axiosAuth.interceptors.response.use((response) => {
  const expDate = store.getters["auth/getExpirationDate"];
  const authenticated = store.getters["auth/isAuthenticated"];
  const timeLeft = expDate - new Date().valueOf();
  if (authenticated && timeLeft < 600000 && !lock) {
    lock = true;
    store
      .dispatch("auth/renewToken")
      .then(() => {
        lock = false;
        // return response;
      })
      .catch(() => {
        lock = false;
        store.dispatch("auth/logout");
      });
  }
  return response;
});
export default axiosAuth;
