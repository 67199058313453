<template>
  <div v-bar class="w-full">
    <div class="w-full bg-csirt pt-32">
      <div
        class="container h-full mx-auto flex justify-center items-center mt-64 md:mt-48"
      >
        <vc-start>
          <template #content>
            <h2 class="text-orange-600 text-3xl font-bold leading-normal mb-2">
              {{ $t("login.registro") }}
            </h2>
            <!-- <div
              class="w-full md:w-2/3 px-5 py-1 bg-orange-200 text-orange-400 rounded-sm my-2 text-sm"
            >
              <span>Para poder informarte registrate</span>
            </div>-->
            <vc-input
              v-model="nombre.value"
              :form-field="nombre"
              @input="$v.nombre.value.$touch()"
            />
            <div v-if="!$v.nombre.value.maxLength" class="error">
              *{{ $t("login.errorNombre1") }}
              {{ $v.nombre.value.$params.maxLength.max }}
              {{ $t("login.errorNombre2") }}.
            </div>

            <vc-input
              v-model="apellido.value"
              :form-field="apellido"
              @input="$v.apellido.value.$touch()"
            />
            <div v-if="!$v.apellido.value.maxLength" class="error">
              *{{ $t("login.errorApellido1") }}
              {{ $v.apellido.value.$params.maxLength.max }}
              {{ $t("login.errorApellido2") }}.
            </div>

            <vc-input
              v-model="email.value"
              :form-field="email"
              @input="
                $v.email.value.$touch();
                errorEmail = '';
              "
            />
            <div v-if="errorEmail" class="error">*{{ errorEmail }}</div>

            <div v-if="!$v.email.value.email" class="error">
              *{{ $t("login.errorForm1") }}
              {{ $v.email.value.$params.email.type }}
              {{ $t("login.errorForm2") }}.
            </div>
            <vc-input
              v-model="empresaNombre.value"
              :form-field="empresaNombre"
              @input="$v.empresaNombre.value.$touch()"
            />
            <div v-if="!$v.empresaNombre.value.maxLength" class="error">
              *{{ $t("login.errorEmpresaSize1") }}
              {{ $v.empresaNombre.value.$params.maxLength.max }}
              {{ $t("login.errorEmpresaSize2") }}.
            </div>
            <div
              v-if="
                $v.empresaNombre.value.$error &&
                !$v.empresaNombre.value.required
              "
              class="error"
            >
              *{{ $t("login.errorEmpresaRequerida") }}.
            </div>

            <vc-input
              v-model="empresaCif.value"
              :form-field="empresaCif"
              @input="$v.empresaCif.value.$touch()"
            />
            <div
              v-if="
                !$v.empresaCif.value.maxLength || !$v.empresaCif.value.minLength
              "
              class="error"
            >
              *{{ $t("login.errorCIF") }}.
            </div>

            <vc-input
              v-model="ciudad.value"
              :form-field="ciudad"
              @input="$v.ciudad.value.$touch()"
            />
            <div v-if="!$v.ciudad.value.maxLength" class="error">
              *{{ $t("login.errorCiudad1") }}
              {{ $v.ciudad.value.$params.maxLength.max }}
              {{ $t("login.errorCiudad2") }}.
            </div>
            <!--  -->
            <div class="mb-4">
              <label for="province" class="clearfix">{{ $t("login.provincia") }}:</label>
              <div v-if="listaProvincias.length">
                <SelectCustom
                  v-model="province"
                  :options="listaProvincias"
                  stylewrap="form-control h-8 w-full md:w-2/3 mt-2 py-1 px-4 border border-black rounded text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none"
                  styleoption=" p-1 text-sm  text-orange-400 text-left hover:text-white hover:bg-orange-600"
                  styleoptionwrap="md:w-2/3 bg-white border-black border border-t-0 h-32"
                  styleicon="border-black"
                />
              </div>
            </div>
            <div class="mb-4">
              <label for="sector" class="clearfix">Sector:</label>
              <div v-if="listaSectores.length">
                <SelectCustom
                  v-model="sector"
                  :options="listaSectores"
                  stylewrap="form-control h-8 w-full md:w-2/3 mt-2 py-1 px-4 border border-black rounded text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none"
                  styleoption=" p-1 text-sm  text-orange-400 text-left hover:text-white hover:bg-orange-600"
                  styleoptionwrap="md:w-2/3 bg-white border-black border border-t-0 h-32"
                  styleicon="border-black"
                />
              </div>
            </div>

            <!--  -->
            <div class="flex items-center mt-2">
              <input
                id="law"
                v-model="lopd"
                class="h-4"
                type="checkbox"
                value="acepted"
                @input="$v.lopd.$touch()"
              />
              <label for="law" class="text-xs font-semibold tracking-tighter">
                {{ $t("login.checkbox1") }}&nbsp;
                <a
                  class="text-orange-400"
                  target="_blank"
                  href="https://concienciat.gva.es/aviso-legal/"
                >
                  {{ $t("login.checkbox2") }}</a
                >.
              </label>
            </div>

            <div class="w-full md:w-2/3">
              <vc-captcha
                ref="captcha"
                @validated="
                  (val) => {
                    captcha = val;
                  }
                "
              />
            </div>

            <vc-btn-simple
              name="Enviar"
              style-wrap="py-2 px-15 bg-orange-600 text-white mt-5 hover:bg-orange-400 focus:outline-none"
              disable-style="py-2 px-15 text-white mt-5 focus:outline-none bg-gray-500 border-gray-500"
              :disabled="
                $v.nombre.value.$invalid ||
                $v.apellido.value.$invalid ||
                $v.empresaCif.value.$invalid ||
                $v.email.value.$invalid ||
                $v.empresaNombre.value.$invalid ||
                $v.ciudad.value.$invalid ||
                !$v.lopd.$model ||
                !captcha
              "
              @click="doSingup"
            />
          </template>
          <template #aside>
            <div class="flex-grow">
              <div class="flex justify-end mt-3">
                <div class="flex items-center justify-end">
                  <div class="w-10 px-1">
                    <button
                      class="w-full p-1 text-xs text-white font-semibold hover:bg-white hover:text-orange-400 focus:outline-none uppercase leading-none"
                      :class="styleLangES"
                      @click="changeLang('es')"
                    >
                      CAS
                    </button>
                  </div>
                  <div class="w-10 px-1">
                    <button
                      class="p-1 text-xs text-white font-semibold hover:bg-white hover:text-orange-400 focus:outline-none uppercase leading-none"
                      :class="styleLangVal"
                      @click="changeLang('val')"
                    >
                      VAL
                    </button>
                  </div>
                </div>
                <a
                  class="ml-3 mt-1 text-white"
                  :href="`2.0.1-manual-avaluat-${
                    $i18n.locale === 'es' ? 'cas' : 'val'
                  }.pdf`"
                  download
                >
                  <span
                    class="inline-block h-4 rounded-full fill-current transition-duration-100"
                  >
                    <vc-icon :icon="manualIcon" />
                  </span>
                </a>
              </div>
              <h2 class="text-white text-3xl font-semibold mt-2">
                {{ $t("login.registrado") }}
              </h2>
              {{ $v.fields }}
              <div class="block text-right mt-3">
                <router-link
                  to="/"
                  href="#"
                  class="uppercase text-sm text-white font-bold tracking-tight mr-1 flex items-center"
                >
                  {{ $t("login.accederCuenta") }}
                  <vc-icon
                    :icon="arrowIcon"
                    class="fill-current h-2 w-2 inline-block ml-3"
                  />
                </router-link>
              </div>
            </div>

            <div class="h-auto mb-1 opacity-75 w-32 self-end mt-5 md:mt-0">
              <vc-icon :icon="marcaCsirtIcon" class="text-white fill-current" />
            </div>
          </template>
        </vc-start>
      </div>
    </div>
  </div>
</template>

<script>
import {
  email,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

import { arrowIcon, manualIcon, marcaCsirtIcon } from "@/assets/data/icons.js";
import axios from "axios";
import axiosAuth from "@/axios-auth";
import axiosBasic from "../axios-basic";
import BtnSimple from "@/components/BtnSimple.vue";
import Captcha from "@/components/Captcha.vue";
import Icon from "@/components/icon/Icon.vue";
import SelectCustom from "@/components/SelectCustom.vue";
import Start from "@/components/Start.vue";
import StartInput from "@/components/StartInput.vue";

const touchMap = new WeakMap();

export default {
  name: "SignUp",
  data() {
    return {
      captcha: false,
      arrowIcon,
      marcaCsirtIcon,
      manualIcon,
      provinceList: [],
      sectorList: [],
      province: 1,
      sector: 1,
      lopd: false,
      nombre: {
        name: "nombre",
        type: "text",
        displayName: this.$t("login.nombre"),
        placeholder: this.$t("login.nombre"),
        id: "nombre",
        value: "",
      },
      apellido: {
        name: "apellido",
        type: "text",
        displayName: this.$t("login.apellidos"),
        placeholder: this.$t("login.apellidos"),
        id: "apellido",
        value: "",
      },
      email: {
        name: "email",
        type: "email",
        displayName: `${this.$t("login.email")} *`,
        placeholder: this.$t("login.email"),
        id: "email",
        value: "",
      },
      empresaNombre: {
        name: "nombreempresa",
        type: "text",
        displayName: `${this.$t("login.empresa")} *`,
        placeholder: this.$t("login.empresa"),
        id: "nombreempresa",
        value: "",
      },
      empresaCif: {
        name: "cifempresa",
        type: "text",
        displayName: "CIF",
        placeholder: "CIF",
        id: "cifempresa",
        value: "",
      },
      ciudad: {
        name: "ciudad",
        type: "text",
        displayName: this.$t("login.ciudad"),
        placeholder: this.$t("login.ciudad"),
        id: "nombreCiudad",
        value: "",
      },
      errorEmail: "",
    };
  },
  computed: {
    ...mapGetters({
      getTypes: "enterprise/getTypes",
      getProvinces: "enterprise/getProvinces",
      getSectores: "enterprise/getTypes",
    }),
    lang() {
      return this.$i18n.locale;
    },
    listaProvincias() {
      return this.provinceList.lenght <= 0 ? null : this.provinceList;
    },
    listaSectores() {
      return this.sectorList.lenght <= 0 ? null : this.sectorList;
    },
    styleLangES() {
      return this.lang == "es"
        ? "bg-white text-orange-400 cursor-default"
        : "bg-transparent text-white";
    },
    styleLangVal() {
      return this.lang == "val"
        ? "bg-white text-orange-400 cursor-default"
        : "bg-transparent text-white";
    },
  },
  components: {
    vcIcon: Icon,
    vcStart: Start,
    vcInput: StartInput,
    SelectCustom,
    vcBtnSimple: BtnSimple,
    vcCaptcha: Captcha,
  },
  validations: {
    email: {
      value: {
        required,
        email,
        maxLength: maxLength(50),
      },
    },
    empresaNombre: {
      value: {
        required,
        maxLength: maxLength(100),
      },
    },
    empresaCif: {
      value: {
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
    },
    nombre: {
      value: {
        maxLength: maxLength(20),
      },
    },
    apellido: {
      value: {
        maxLength: maxLength(45),
      },
    },
    ciudad: {
      value: {
        maxLength: maxLength(50),
      },
    },
    lopd: {
      required,
    },
  },
  methods: {
    ...mapActions({
      singup: "auth/singup",
      setProvinces: "enterprise/setProvinces",
      setSectores: "enterprise/setTypes",
    }),
    changeLang(lang) {
      if (this.lang !== lang) {
        window.localStorage.setItem("lang", lang);
        axios.defaults.headers.common["lang"] = lang;
        axiosBasic.defaults.headers.common["lang"] = lang;
        axiosAuth.defaults.headers.common["lang"] = lang;
        this.$i18n.locale = lang;
        lang === "val" ? this.$moment.locale("ca") : this.$moment.locale("es");
        this.changedLang();
      }
    },
    changedLang() {
      this.nombre.displayName = this.$t("login.nombre");
      this.nombre.placeholder = this.$t("login.nombre");
      this.apellido.displayName = this.$t("login.apellidos");
      this.apellido.placeholder = this.$t("login.apellidos");
      this.email.displayName = this.$t("login.email");
      this.email.placeholder = this.$t("login.email");
      this.empresaNombre.displayName = this.$t("login.empresa");
      this.empresaNombre.placeholder = this.$t("login.empresa");
      this.ciudad.displayName = this.$t("login.ciudad");
      this.ciudad.placeholder = this.$t("login.ciudad");
    },
    doSingup() {
      const payload = {
        email: this.email.value,
        name: this.nombre.value,
        surnames: this.apellido.value,
        company: this.empresaNombre.value,
        companyType: this.sector,
        province: this.province,
        town: this.ciudad.value,
        cif: this.empresaCif.value,
        _csrf: this.$refs.captcha.getCsrfToken(),
        captcha: this.$refs.captcha.getValue(),
      };
      if (payload.email && payload.company) {
        this.singup(payload)
          .then(() => {
            this.$router.push({ name: "newUser" });
          })
          .catch(() => {
            this.$refs.captcha.restart();
            this.errorEmail = `${this.$t("login.errorEmail")}.`;
          });
      }
    },
    setProvincesValues() {
      this.getProvinces.forEach((province) => {
        this.provinceList.push({
          value: province.id,
          name: province.name,
        });
      });
    },
    setSectoresValues() {
      this.getSectores.forEach((sector) => {
        this.sectorList.push({
          value: sector.id,
          name: sector.name,
        });
      });
    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
  },
  created() {
    if (!this.getProvinces) {
      this.setProvinces().then(() => {
        this.setProvincesValues();
      });
    } else {
      this.setProvincesValues();
    }

    if (!this.getSectores) {
      this.setSectores().then(() => {
        this.setSectoresValues();
      });
    } else {
      this.setSectoresValues();
    }
  },
};
</script>
