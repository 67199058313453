import axios from "axios";

import config from "./config";
import i18n from "./i18n";
import store from "./store/store";

const axiosBasic = axios.create();

/*El sufijo 'basic' lo utilizo para referirme a que no tiene interceptores para renovar el token*/
axiosBasic.defaults.baseURL = config.URL_SERVER;

i18n.locale = window.localStorage.getItem("lang") || i18n.locale;
// const userId = localStorage.getItem("userId");
const token = localStorage.getItem(`token`);
axiosBasic.defaults.headers.common["x-access-token"] = token;
axiosBasic.defaults.headers.common["lang"] = i18n.locale;
axiosBasic.interceptors.request.use(
  (config) => {
    const expDate = store.getters["auth/getExpirationDate"];
    const authenticated = store.getters["auth/isAuthenticated"];
    const timeLeft = expDate - new Date().valueOf();
    if (!authenticated || timeLeft <= 0) {
      store.dispatch("auth/logout");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default axiosBasic;
