<template>
  <div class="my-10">
    <div v-if="!haveSubquestions">
      <p class="text-xl text-gray-600 font-semibold">{{data.question}}</p>
      <div class="mt-5">
        <div class="flex flex-wrap">
          <vc-question-button
            v-for="(answer, index) in answers"
            :key="index"
            :value="answer.value"
            :name="answer.label"
            :active="data.answer === answer.value"
            :available="answer.available"
            size="px-1 mt-2 w-1/2 md:px-2 md:w-1/3 md:px-4 lg:w-1/6 lg:mt-0 "
            @change="changeValue"
          ></vc-question-button>
        </div>
      </div>
      <vc-comment :key="index" v-model="data.notes"></vc-comment>
    </div>
    <div v-if="haveSubquestions">
      <div class="py-5">
        <span class="text-gray-600 text-xl font-semibold">{{data.question}}</span>
      </div>
      <div class="flex flex-col">
        <vc-sub-question
          v-for="(question, index) in data.subquestions"
          :key="index"
          :data="question"
          :answers="answers"
        ></vc-sub-question>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionButton from "@/components/questions/QuestionButton.vue";
import Comment from "@/components/questions/Comment.vue";
import SubQuestion from "@/components/questions/SubQuestion.vue";
export default {
  data() {
    return {
      question: "",
      numberAnswers: 6
    };
  },

  props: {
    data: Object,
    answers: Array,
    index: Number
  },
  components: {
    vcQuestionButton: QuestionButton,
    vcComment: Comment,
    vcSubQuestion: SubQuestion
  },
  computed: {
    haveSubquestions() {
      return this.data.subquestions.length > 0;
    }
  },

  methods: {
    changeValue: function(newValue) {
      this.data.answer = newValue;
    }
  }
};
</script>
