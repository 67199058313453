<template>
  <div :class="size">
    <button
      :class="{'active':active}"
      class="btn btn-question py-2 px-1 focus:outline-none truncate"
      :disabled="!available"
      @click="emitValue"
    >{{name}}</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: this.value
    };
  },
  props: {
    value: Number,
    name: String,
    available: null,
    active: Boolean,
    size: {
      type: String,
      default: "w-1/6"
    }
  },
  methods: {
    emitValue() {
      this.$emit("change", this.inputValue);
    }
  },
  watch: {
    inputValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
