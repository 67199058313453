import axios from "axios";
import jwt_decode from "jwt-decode";

import axiosAuth from "../../axios-auth";
import axiosBasic from "../../axios-basic";

let timer;
export default {
  namespaced: true,
  state: {
    token: localStorage.getItem(`token`) || null,
    userId: localStorage.getItem("userId") || null,
    expirationDate: localStorage.getItem(`expirationDate`) || null,
    userSession: localStorage.getItem("userSession") || null,
    roles: localStorage.getItem("roles") || null,
    email: localStorage.getItem(`email`) || null,
  },
  getters: {
    user: (state) => state.userId,
    userEmail: (state) => state.email,
    isAuthenticated: (state) => state.token !== null,
    getRoles: (state) => state.roles,
    hasToken: (state) => state.token !== null,
    getExpirationDate: (state) => state.expirationDate,
  },
  actions: {
    login: ({ commit, dispatch }, { email, password, _csrf, captcha }) => {
      return new Promise((resolve, reject) => {
        axios
          .post("/login", { email, password, _csrf, captcha })
          .then((res) => res.data)
          .then((data) => {
            const sessionDetails = jwt_decode(data.token); // contains the JSON of decoded token
            commit("setToken", {
              token: data.token,
              userId: sessionDetails.userId,
              expirationDate: sessionDetails.exp,
              userSession: sessionDetails,
              roles: sessionDetails.roles,
            });

            const timeLeft = sessionDetails.exp - new Date().valueOf();
            dispatch("logoutTimeout", timeLeft);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    singup: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post("/register", payload)
          .then((res) => res.data)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout: ({ commit }) => {
      return new Promise((resolve) => {
        commit("clearAuth");
        resolve();
      });
    },
    renewToken: ({ dispatch, commit }) => {
      return new Promise((resolve, reject) => {
        // let headers = {
        //   "Access-Control-Allow-Headers": "x-access-token",
        //   "x-access-token": localStorage.getItem(`token${this.state.userId}`)
        // };
        axios.defaults.headers.common["x-access-token"] =
          localStorage.getItem(`token`);
        axios
          .post("/renew")
          .then((res) => {
            const sessionDetails = jwt_decode(res.data.token); // contains the JSON of decoded token
            commit("setToken", {
              token: res.data.token,
              userId: sessionDetails.user,
              expirationDate: sessionDetails.exp,
              userSession: sessionDetails,
              roles: sessionDetails.roles,
            });

            axiosAuth.defaults.headers.common["x-access-token"] =
              res.data.token;
            axiosBasic.defaults.headers.common["x-access-token"] =
              res.data.token;

            const timeLeft = sessionDetails.exp - new Date().valueOf();

            dispatch("logoutTimeout", timeLeft);
            resolve(res.data.token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logoutTimeout: ({ dispatch }, logoutTime) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        dispatch("logout");
      }, logoutTime);
    },
    changePassword: ({ dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post("/password", payload)
          .then(() => {
            dispatch("logout");
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    forgotPassword: (_, payload) => {
      axios.post("/forgotPassword", payload);
    },
  },
  mutations: {
    setToken: (state, userData) => {
      state.token = userData.token;
      state.userId = userData.userId;
      state.expirationDate = userData.expirationDate;
      state.roles = userData.roles;
      state.email = userData.userSession.email;

      localStorage.setItem(`token`, userData.token);
      localStorage.setItem("userId", userData.userId);
      localStorage.setItem("userSession", userData.userSession);
      localStorage.setItem(`expirationDate`, userData.expirationDate);
      localStorage.setItem("roles", userData.roles);
      localStorage.setItem(`email`, userData.userSession.email);

      axiosAuth.defaults.headers.common["x-access-token"] = userData.token;
      axiosBasic.defaults.headers.common["x-access-token"] = userData.token;
    },
    clearAuth: (state) => {
      localStorage.removeItem(`token`);
      localStorage.removeItem("userId");
      localStorage.removeItem(`expirationDate`);
      localStorage.removeItem("roles");
      localStorage.removeItem(`email`);
      state.token = null;
      state.userId = null;
      state.expirationDate = null;
      state.roles = null;
      state.email = null;
    },
  },
};
