import axios from "../../axios-auth";
import axiosBasic from "../../axios-basic";
import store from "../store";

export default {
  namespaced: true,
  state: {
    tests: null,
    savedTest: null,
    sections: null,
    test: null,
    testSections: null,
    testSummary: null,
  },
  getters: {
    getTests: (state) => state.tests,
    getSections: (state) => state.sections,
    getTest: (state) => state.test,
    getTestSections: (state) => state.testSections,
    getTestSummary: (state) => state.testSummary,
    getSavedTest: (state) => state.savedTest,
  },
  actions: {
    setTests: ({ commit }) => {
      commit("setTests", null);
      axios.get("/tests").then((tests) => {
        commit("setTests", tests.data);
      });
    },
    setTestsBasic: ({ commit }) => {
      // .then(r => r.data)
      axiosBasic.get("/tests").then((tests) => {
        commit("setTests", tests.data);
      });
    },
    setTestsAsync: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios
          .get("/tests")
          .then((r) => r.data)
          .then((tests) => {
            commit("setTests", tests);
            resolve(tests);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setCompanyTests: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axiosBasic
          .get("/tests/company")
          .then((r) => r.data)
          .then((tests) => {
            commit("setTests", tests);
            resolve(tests);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setSections: ({ commit }) => {
      commit("setSections", null);
      axios
        .get("/tests/sections")
        .then((r) => r.data)
        .then((sections) => {
          commit("setSections", sections);
        });
    },

    setTest({ commit, reject }, { idTest, idSection }) {
      return axios
        .get(`/tests/${idTest}/${idSection}`)
        .then((r) => r.data)
        .then((test) => {
          commit("setTest", test);
        });
    },
    setTestBasic({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosBasic
          .get(`/tests/${params.idTest}/${params.idSection}`)
          .then((r) => r.data)
          .then((test) => {
            commit("setTest", test);
            resolve(test);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setTestAsync({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/tests/${params.idTest}/${params.idSection}`)
          .then((r) => r.data)
          .then((test) => {
            commit("setTest", test);
            resolve(test);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setSavedTest({ commit }, { idTest, idSection }) {
      commit("setSavedTest", `${idTest}${idSection}`);
    },

    setTestSections({ commit }, idTest) {
      axios
        .get(`/tests/sections/${idTest}`)
        .then((r) => r.data)
        .then((sections) => {
          commit("setTestSections", sections);
        });
    },
    setTestSectionsBasic({ commit }, idTest) {
      return new Promise((resolve, reject) => {
        axiosBasic
          .get(`/tests/sections/${idTest}`)
          .then((r) => r.data)
          .then((sections) => {
            commit("setTestSections", sections);
            resolve(sections);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setTestSectionsAsync({ commit }, idTest) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/tests/sections/${idTest}`)
          .then((r) => r.data)
          .then((sections) => {
            commit("setTestSections", sections);
            resolve(sections);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //Mira, yo lo siento mucho por esta llamada triple encadenada de la muerte,
    // Si te causa dolor de cabeza te invito a cerveza. Fdo: Ricardo
    setTestSectionQuestionAsync() {
      return new Promise((resolve) => {
        const result = [];
        axios
          .get("/tests")
          .then((tests) => {
            if (tests.data.length) {
              result.push(tests.data[0].id);
              return axios
                .get(`/tests/sections/${result[0]}`)
                .then((sections) => {
                  result.push(sections.data[0].id);
                  return axios
                    .get(`/tests/${result[0]}/${result[1]}`)
                    .then((r) => r.data)
                    .then((questions) => {
                      result.push(questions.questions[0].id);
                      return result;
                    });
                });
            }
          })
          .then((result) => {
            resolve(result);
          });
      });
    },

    setTestSummary({ commit, dispatch }, { idTest, idTestCompare }) {
      commit("setTestSummary", null);

      if (idTestCompare) {
        const tests = [];
        axios.get(`/tests/summary/${idTest}`).then((summary) => {
          tests.push(summary.data);
          axios.get(`/tests/summary/${idTestCompare}`).then((summ) => {
            tests.push(summ.data);
            commit("setTestSummary", tests);
          });
        });
      } else {
        axios.get(`/tests/summary/${idTest}`).then((summary) => {
          commit("setTestSummary", summary.data);
          dispatch("setTest", {
            idSection: summary.data.sections.sections[0].id,
            idTest,
          });
        });
      }
    },
    setTestSummaryBasic({ commit }, { idTest, idTestCompare }) {
      commit("setTestSummary", null);

      if (idTestCompare) {
        const tests = [];
        axiosBasic
          .get(`/tests/summary/${idTest}`)
          .then((r) => r.data)
          .then((summary) => {
            tests.push(summary);
            axiosBasic
              .get(`/tests/summary/${idTestCompare}`)
              .then((r) => r.data)
              .then((summ) => {
                tests.push(summ);
                commit("setTestSummary", tests);
              });
          });
      } else {
        axiosBasic.get(`/tests/summary/${idTest}`).then((summary) => {
          commit("setTestSummary", summary.data);
        });
      }
    },
    updateTestSection: (_, { idTest, idSection, test }) => {
      axios
        .put(`/tests/${idTest}/${idSection}`, test)
        .then((r) => r.status == 200);
    },
    updateTestSectionAsync: (_, { idTest, idSection, test }) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`/tests/${idTest}/${idSection}`, test)
          .then((r) => {
            resolve(r.status == 200);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    createTest: (_, newTest) => {
      return new Promise((resolve, reject) => {
        axios
          .post("/tests", newTest)
          .then((r) => r.data)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    copyTest: (_, newTest) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/tests/${newTest.testCopy}`, newTest)
          .then((r) => r.data)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteTest: ({ dispatch }, id) => {
      axios.delete(`/tests/${id}`).then(() => {
        dispatch("setTests");
        store.dispatch("tasks/setTasks");
      });
    },
  },
  mutations: {
    setTests: (state, tests) => {
      state.tests = tests;
    },
    setSections: (state, sections) => {
      state.sections = sections;
    },
    setTest: (state, test) => {
      state.test = test;
    },
    setSavedTest: (state, testAndSection) => {
      state.savedTest = testAndSection;
    },
    setTestSections: (state, sections) => {
      state.testSections = sections;
    },
    setTestSummary: (state, summary) => {
      state.testSummary = summary;
    },
  },
};
