var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-4 flex flex-col",attrs:{"id":"container"}},[_c('div',{staticClass:"cursor-pointer bg-orange-400 text-white text-center",attrs:{"id":"refresh"},on:{"click":_vm.refresh}},[_vm._v(" "+_vm._s(_vm.$t("captcha.actualizar"))+" ")]),_c('div',{staticClass:"flex items-center justify-center",attrs:{"id":"cap"}},[_c('img',{staticClass:"table",attrs:{"src":_vm.image,"id":"canvas","width":"250","height":"100px","alt":"Captcha"}})]),_c('div',{staticClass:"text-center bg-gray-200 pb-4",attrs:{"id":"form"}},[_c('p',{attrs:{"id":"ctitle"}},[_vm._v(_vm._s(_vm.$t("captcha.introduce")))]),_c('div',{staticClass:"flex justify-center items-center"},[_c('input',{ref:"inputCanvas",staticClass:"form-control h-8 w-32 py-2 px-4 border border-black rounded-tl-sm rounded-bl-sm text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none disabled:bg-gray-300",class:{
            'border-2 border-orange-400 bg-orange-400 text-white ': _vm.validated,
            'border-r-0': !_vm.validated,
          },attrs:{"disabled":_vm.refreshing || _vm.validated,"autocomplete":"off","id":"input","type":"text","maxlength":"4","name":"input","required":""}}),_c('button',{staticClass:"py-1 px-2 text-white disabled:bg-gray-300 focus:outline-none",class:{
            'bg-orange-400': !_vm.validated,
            'bg-orange-300 cursor-not-allowed': _vm.validated,
          },attrs:{"disabled":_vm.refreshing || _vm.validated,"id":"submit","value":"Submit","type":"submit","required":""},on:{"click":_vm.validate}},[_vm._v(" Validar ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }