<template>
  <div>
    <apexchart
      width="100%"
      height="400px"
      type="bar"
      :options="options"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    showLabels: {
      default: true,
    },
    labelsSeries: {
      //Esto indica en que series se veran los valores
      default: () => [0, 1],
    },
    labelsOffsetX: {
      default: 0,
    },
    showLegend: {
      default: true,
    },
    categories: Array,
    series: Array,
    tooltip: Boolean,
  },
  data() {
    return {
      options: {
        colors: ["#f08a01", "#F6B578"], // Estos colores indican los qu se usaran en la grafica
        // stroke: {
        // colors: ["#d15e15", "transparent"],
        //   // curve: "smooth",
        //   // width: 2
        //   // lineCap: "butt"
        // },

        //Interior de las barras (Colores)
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
            gradientToColors: ["#f08a01", "#F6B578"],
          },
          // colors: ["#d15e15", "#e7a272"],
          opacity: 1,
        },
        markers: {
          colors: ["#d15e15"],
          strokeWidth: 0,
        },

        grid: {
          borderColor: "#F6B578", //"#e7a272",
          position: "back",
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: this.showLegend,
          labels: {
            useSeriesColors: true,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        tooltip: {
          enabled: this.tooltip,
          marker: {
            show: false,
          },
          x: {
            format: String,
            show: false,
          },
          y: {
            format: Number,
          },
        },

        //Labels de los valores de cada barra
        dataLabels: {
          enabled: this.showLabels,
          enabledOnSeries: this.labelsSeries,
          offsetY: 0,
          offsetX: this.labelsOffsetX,
          style: {
            fontSize: "1em",
            colors: ["#d15e15"],
          },
        },

        chart: {
          id: "vuechart-example",
          foreColor: "#d15e15",
          toolbar: {
            //Esto te permite descargar el grafico generado.
            show: false,
          },
        },

        yaxis: {
          axisBorder: {
            show: true,
            color: "#d15e15",
            height: "100%",
            width: 1,
            offsetX: 0,
            offsetY: 5,
          },
          labels: {
            style: {
              fontSize: "1rem",
              cssClass: "apexcharts-yaxis-label",
            },
          },
          tickAmount: 2,
          min: 0,
          max: 100,
          decimalsInFloat: 0,
        },

        xaxis: {
          axisBorder: {
            show: true,
            color: "#d15e15",
            height: 1,
            width: "100%",
            offsetX: -5,
            offsetY: 0,
          },
          labels: {
            trim: true,
            style: {
              fontSize: "1rem",
              cssClass: "apexcharts-xaxis-label",
              colors: [
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
                "#f08a01",
              ],
            },
          },
          tooltip: {
            enabled: false,
          },

          categories: this.categories,
        },
      },
    };
  },
};
</script>
