<template>
  <div class="w-4/5 flex relative">
    <div class="form-background-rotate"></div>
    <div class="bg-orange-400 shadow-md z-20 w-full flex flex-col lg:flex-row">
      <div class="bg-white w-full px-10 py-5 pb-8 pl-15 lg:w-2/3">
        <slot name="content"></slot>
      </div>
      <div class="bg-orange-400 w-full text-white py-3 px-5 flex flex-col lg:w-1/3">
        <slot name="aside"></slot>
        <p class="text-orange-200 text-right mb-3 text-sm">Avalua-T {{ version }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version: null
    }
  },
  created() {
    const pjson = require('../../package.json')
    this.version = pjson.version
  },
};
</script>
