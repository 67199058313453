<template>
  <!-- <div class="py-1"> -->
  <div class="relative h-6 rounded bg-orange-200">
    <div class="absolute w-full text-center font-bold text-m z-10">{{completed}}%</div>
    <div
      class="absolute top-0 h-6 rounded transition-duration-250"
      :class="gradientColor"
      :style="{width: `${completed}%`}"
    ></div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    secundaryColor: {
      default: false
    }
  },
  computed: {
    completed() {
      return this.actualPercentage >= 100 ? 100 : this.actualPercentage;
    },
    gradientColor() {
      return this.secundaryColor ? "gradient-gray" : "gradient-orange";
    },
    actualPercentage: {
      get() {
        return this.percentage;
      },

      set(val) {
        this.percentage = val;
      }
    }
  }
};
</script>