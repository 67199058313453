var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full bg-csirt"},[_c('div',{staticClass:"container mx-auto h-full flex justify-center items-center"},[_c('vc-start',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('h2',{staticClass:"text-orange-600 text-3xl font-bold leading-normal"},[_vm._v(" "+_vm._s(_vm.$t("login.acceso"))+" ")]),_c('div',{staticClass:"w-full md:w-2/3 px-5 py-1 bg-orange-200 text-orange-400 rounded-sm my-2 text-sm"},[_c('span',[_vm._v(_vm._s(_vm.$t("login.sesionNecesaria")))])]),_c('vc-input',{attrs:{"form-field":_vm.email},on:{"input":function($event){return _vm.$v.email.value.$touch()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.logIn.apply(null, arguments)}},model:{value:(_vm.email.value),callback:function ($$v) {_vm.$set(_vm.email, "value", $$v)},expression:"email.value"}}),(!_vm.$v.email.value.email)?_c('div',{staticClass:"error"},[_vm._v(" *"+_vm._s(_vm.$t("login.errorForm1"))+" "+_vm._s(_vm.$v.email.value.$params.email.type)+" "+_vm._s(_vm.$t("login.errorForm2"))+". ")]):_vm._e(),_c('vc-input',{attrs:{"form-field":_vm.password},on:{"input":function($event){return _vm.$v.password.value.$touch()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.logIn.apply(null, arguments)}},model:{value:(_vm.password.value),callback:function ($$v) {_vm.$set(_vm.password, "value", $$v)},expression:"password.value"}}),(_vm.errorMsg)?_c('div',[_c('span',{staticClass:"text-md font-bold text-orange-600"},[_vm._v(_vm._s(_vm.errorMsg))])]):_vm._e(),_c('div',{staticClass:"w-full md:w-2/3"},[_c('vc-captcha',{ref:"captcha",on:{"validated":(val) => {
                _vm.captcha = val;
              }}})],1),_c('button',{staticClass:"btn-square mt-5 w-32 rounded-sm",attrs:{"disabled":_vm.loading ||
            _vm.$v.email.value.$invalid ||
            _vm.$v.password.value.$invalid ||
            !_vm.captcha},on:{"click":function($event){$event.preventDefault();return _vm.logIn.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.loadingText)+" ")]),_c('router-link',{staticClass:"text-xs tracking-tight ml-3 align-bottom",attrs:{"to":"/forgotPassword"}},[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(_vm.$t("login.passOlvidada")))])])]},proxy:true},{key:"aside",fn:function(){return [_c('div',{staticClass:"flex-grow"},[_c('div',{staticClass:"flex justify-end mt-3"},[_c('div',{staticClass:"flex items-center justify-end"},[_c('div',{staticClass:"w-10 px-1"},[_c('button',{staticClass:"w-full p-1 text-xs text-white font-semibold hover:bg-white hover:text-orange-400 focus:outline-none uppercase leading-none",class:_vm.styleLangES,on:{"click":function($event){return _vm.changeLang('es')}}},[_vm._v(" CAS ")])]),_c('div',{staticClass:"w-10 px-1"},[_c('button',{staticClass:"p-1 text-xs text-white font-semibold hover:bg-white hover:text-orange-400 focus:outline-none uppercase leading-none",class:_vm.styleLangVal,on:{"click":function($event){return _vm.changeLang('val')}}},[_vm._v(" VAL ")])])]),_c('a',{staticClass:"ml-3 mt-1 text-white",attrs:{"href":`2.0.1-manual-avaluat-${
                _vm.$i18n.locale === 'es' ? 'cas' : 'val'
              }.pdf`,"download":""}},[_c('span',{staticClass:"inline-block h-4 rounded-full fill-current transition-duration-100"},[_c('vc-icon',{attrs:{"icon":_vm.manualIcon}})],1)])]),_c('h2',{staticClass:"text-white text-3xl font-semibold mt-3"},[_vm._v(" "+_vm._s(_vm.$t("login.registrado"))+" ")]),_c('p',{staticClass:"my-3 text-orange-200 leading-tight text-sm"},[_vm._v(" "+_vm._s(_vm.$t("login.animate"))+" ")]),_c('div',{staticClass:"block text-right mt-2"},[_c('router-link',{staticClass:"uppercase text-sm text-white font-bold tracking-tight mr-1 flex items-center",attrs:{"to":"/singup"}},[_vm._v(" "+_vm._s(_vm.$t("login.registrate"))+" "),_c('vc-icon',{staticClass:"fill-current h-2 w-2 inline-block ml-3",attrs:{"icon":_vm.arrowIcon}})],1)],1)]),_c('div',{staticClass:"h-auto mb-1 opacity-75 w-32 self-end mt-5 md:mt-0"},[_c('vc-icon',{staticClass:"text-white fill-current",attrs:{"icon":_vm.marcaCsirtIcon}})],1)]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }