<template>
  <div class="w-full bg-csirt">
    <div class="container mx-auto h-full flex justify-center items-center">
      <vc-start>
        <template #content>
          <h2 class="text-orange-600 text-3xl font-bold leading-normal">
            {{ $t("login.acceso") }}
          </h2>
          <div
            class="w-full md:w-2/3 px-5 py-1 bg-orange-200 text-orange-400 rounded-sm my-2 text-sm"
          >
            <span>{{ $t("login.sesionNecesaria") }}</span>
          </div>

          <vc-input
            v-model="email.value"
            :form-field="email"
            @input="$v.email.value.$touch()"
            @keyup.enter.native="logIn"
          />
          <div v-if="!$v.email.value.email" class="error">
            *{{ $t("login.errorForm1") }}
            {{ $v.email.value.$params.email.type }}
            {{ $t("login.errorForm2") }}.
          </div>

          <vc-input
            v-model="password.value"
            :form-field="password"
            @keyup.enter.native="logIn"
            @input="$v.password.value.$touch()"
          />

          <div v-if="errorMsg">
            <span class="text-md font-bold text-orange-600">{{
              errorMsg
            }}</span>
          </div>
          <div class="w-full md:w-2/3">
            <vc-captcha
              ref="captcha"
              @validated="
                (val) => {
                  captcha = val;
                }
              "
            />
          </div>
          <button
            class="btn-square mt-5 w-32 rounded-sm"
            :disabled="
              loading ||
              $v.email.value.$invalid ||
              $v.password.value.$invalid ||
              !captcha
            "
            @click.prevent="logIn"
          >
            {{ loadingText }}
          </button>
          <router-link
            to="/forgotPassword"
            class="text-xs tracking-tight ml-3 align-bottom"
          >
            <span class="underline">{{ $t("login.passOlvidada") }}</span>
          </router-link>
        </template>
        <template #aside>
          <div class="flex-grow">
            <div class="flex justify-end mt-3">
              <div class="flex items-center justify-end">
                <div class="w-10 px-1">
                  <button
                    class="w-full p-1 text-xs text-white font-semibold hover:bg-white hover:text-orange-400 focus:outline-none uppercase leading-none"
                    :class="styleLangES"
                    @click="changeLang('es')"
                  >
                    CAS
                  </button>
                </div>
                <div class="w-10 px-1">
                  <button
                    class="p-1 text-xs text-white font-semibold hover:bg-white hover:text-orange-400 focus:outline-none uppercase leading-none"
                    :class="styleLangVal"
                    @click="changeLang('val')"
                  >
                    VAL
                  </button>
                </div>
              </div>
              <a
                class="ml-3 mt-1 text-white"
                :href="`2.0.1-manual-avaluat-${
                  $i18n.locale === 'es' ? 'cas' : 'val'
                }.pdf`"
                download
              >
                <span
                  class="inline-block h-4 rounded-full fill-current transition-duration-100"
                >
                  <vc-icon :icon="manualIcon" />
                </span>
              </a>
            </div>
            <h2 class="text-white text-3xl font-semibold mt-3">
              {{ $t("login.registrado") }}
            </h2>
            <p class="my-3 text-orange-200 leading-tight text-sm">
              {{ $t("login.animate") }}
            </p>
            <div class="block text-right mt-2">
              <router-link
                to="/singup"
                class="uppercase text-sm text-white font-bold tracking-tight mr-1 flex items-center"
              >
                {{ $t("login.registrate") }}
                <vc-icon
                  :icon="arrowIcon"
                  class="fill-current h-2 w-2 inline-block ml-3"
                />
              </router-link>
            </div>
          </div>

          <div class="h-auto mb-1 opacity-75 w-32 self-end mt-5 md:mt-0">
            <vc-icon :icon="marcaCsirtIcon" class="text-white fill-current" />
          </div>
        </template>
      </vc-start>
    </div>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import { arrowIcon, manualIcon, marcaCsirtIcon } from "@/assets/data/icons.js";
import axios from "axios";
import axiosAuth from "@/axios-auth";
import Captcha from "@/components/Captcha.vue";
import Icon from "@/components/icon/Icon.vue";
import Start from "@/components/Start.vue";
import StartInput from "@/components/StartInput.vue";

import axiosBasic from "../axios-basic";

export default {
  name: "Login",
  components: {
    vcIcon: Icon,
    vcStart: Start,
    vcInput: StartInput,
    vcCaptcha: Captcha,
  },
  data() {
    return {
      loading: false,
      captcha: false,
      errorMsg: null,
      arrowIcon,
      marcaCsirtIcon,
      manualIcon,
      email: {
        name: "email",
        type: "email",
        displayName: this.$t("login.email"),
        placeholder: this.$t("login.email"),
        id: "email",
        value: "",
      },
      password: {
        name: "password",
        type: "password",
        displayName: this.$t("login.pass"),
        placeholder: this.$t("login.pass"),
        id: "password",
        value: "",
      },
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    loadingText() {
      return this.loading ? this.$t("login.cargando") : "Enviar";
    },
    styleLangES() {
      return this.lang == "es"
        ? "bg-white text-orange-400 cursor-default"
        : "bg-transparent text-white";
    },
    styleLangVal() {
      return this.lang == "val"
        ? "bg-white text-orange-400 cursor-default"
        : "bg-transparent text-white";
    },
  },
  created() {
    this.logout();
  },
  validations: {
    email: {
      value: {
        required,
        email,
      },
    },
    password: {
      value: {
        required,
      },
    },
  },

  methods: {
    ...mapActions({
      logout: "auth/logout",
      login: "auth/login",
    }),
    changeLang(lang) {
      if (this.lang !== lang) {
        window.localStorage.setItem("lang", lang);
        axios.defaults.headers.common["lang"] = lang;
        axiosBasic.defaults.headers.common["lang"] = lang;
        axiosAuth.defaults.headers.common["lang"] = lang;
        this.$i18n.locale = lang;
        lang === "val" ? this.$moment.locale("ca") : this.$moment.locale("es");
        this.changedLang();
      }
    },
    changedLang() {
      this.email.displayName = this.$t("login.email");
      this.email.placeholder = this.$t("login.email");
      this.password.displayName = this.$t("login.pass");
      this.password.placeholder = this.$t("login.pass");
    },
    logIn() {
      const email = this.email.value,
        password = this.password.value;
      if (!this.loading && email && password) {
        this.loading = true;
        this.login({
          email,
          password,
          _csrf: this.$refs.captcha.getCsrfToken(),
          captcha: this.$refs.captcha.getValue(),
        })
          .then(() => {
            this.loading = false;
            this.$router.push({ name: "testDashboard" });
          })
          .catch((err) => {
            this.$refs.captcha.restart();
            if (err.message.includes(404) || err.message.includes(401)) {
              this.errorMsg = this.$t("login.loginErrorIncorrecto");
            } else if (err.message.includes(400)) {
              this.errorMsg = this.$t("login.loginErrorValido");
            } else if (err.message.includes(500)) {
              this.errorMsg = this.$t("login.loginErrorConexion");
            } else if (err.message.includes(505)) {
              this.errorMsg = this.$t("login.loginErrorSesion");
            } else {
              this.errorMsg = `${this.$t("login.loginErrorGenerico")}: ${
                err.message
              }`;
            }
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style></style>
