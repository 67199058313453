<template>
  <div
    ref="box"
    class="box-enable w-full my-8 flex-col md:px-4"
    :class="{ 'box-disable': !enable }"
  >
    <span
      v-if="haveTitle"
      class="text-orange-600 -top-6 left-0 font-bold pl-16 truncate block w-full"
      >{{ title }}</span
    >
    <div class="relative">
      <span
        v-if="haveIcon"
        class="block absolute shadow w-13 h-13 bg-orange-400 z-50 rounded-full -top-4 py-3 text-white fill-current"
      >
        <vc-icon :icon="icon" />
      </span>
      <span
        v-if="haveIcon"
        class="block absolute w-16 h-16 bg-gray-200 z-40 rounded-full -top-6 -left-2"
      />

      <div
        class="bg-white pt-12 pb-8 px-10 rounded h-full overflow-hidden relative shadow"
      >
        <div
          v-if="haveIcon"
          class="absolute border-6 border-orange-400 rounded-full w-20 h-20 bg-gray-200 -top-8 -left-4"
        />
        <div class="w-full h-full">
          <button
            v-if="hasDeleteRole"
            v-tooltip="$t('testDashboard.borrarTest')"
            class="absolute w-6 top-0 right-0 m-2 focus:outline-none"
            @click="deleteTest"
          >
            <span
              class="rounded-full text-orange-400 fill-current hover:text-orange-600"
            >
              <vc-icon :icon="plusIcon" class="rotate-45" />
            </span>
          </button>

          <div class="text-center">
            <h3 class="font-semibold text-base">
              {{ $t("testDashboard.fechaUltimaEdicion") }}:
            </h3>
            <h3 class="text-base">
              {{ test.editDate | moment($t("testDashboard.formatoFecha")) }}
            </h3>
            <div class="mt-4 w-2/3 m-auto">
              <h3 class="font-semibold text-base mb-2">
                {{ $t("testDashboard.completado") }}:
              </h3>
              <!-- <span class="text-orange-400 font-bold text-lg">
                {{ test.percentage }}%
              </span> -->
              <vc-progress-bar :percentage="test.percentage" />
            </div>
            <div class="mt-4 flex flex-col items-center">
              <h3 class="font-semibold text-base -mb-2">
                {{ $t("testDashboard.nota") }}:
              </h3>
              <vc-circle-chart :series="[Math.round(test.score)]" />
            </div>
            <h3 class="-mt-2 font-semibold text-base">
              {{ $t("testDashboard.asignadoA") }}:
            </h3>
            <h3 class="text-base">
              {{ test.assignedTo }}
            </h3>
            <div class="btns-test mt-4 flex justify-between m-auto w-24">
              <div class="h-8 w-8">
                <vc-btn-rounded
                  v-tooltip="$t('testDashboard.verTest')"
                  :icon="verIcon"
                  :disabled="!hasEditionRole"
                  @click="goTest(test.id, test.current, test.completed)"
                />
              </div>
              <div class="h-8 w-8">
                <vc-btn-rounded
                  v-tooltip="$t('testDashboard.descargarTest')"
                  :icon="descargarIcon"
                  :disabled="!test.completed"
                  @click="downloadPdf"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="text-gray-600 text-center text-sm mt-1">
        {{ $t("testDashboard.creadoPor") }}:
        <span class="font-semibold">{{ test.createdBy }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import {
  descargarIcon,
  editarIcon,
  eliminarIcon,
  plusIcon,
  verIcon,
} from "@/assets/data/icons.js";
import BtnRounded from "@/components/BtnRounded.vue";
import CircleChart from "@/components/charts/CircleChartSmall.vue";
import Icon from "@/components/icon/Icon.vue";
import ProgressBar from "@/components/ProgressBar.vue";

import axios from "../axios-auth";

export default {
  components: {
    vcIcon: Icon,
    vcCircleChart: CircleChart,
    vcProgressBar: ProgressBar,
    vcBtnRounded: BtnRounded,
  },
  props: {
    icon: Object,
    test: Object,
    title: {
      type: String,
      default: undefined,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    hasDeleteRole: {
      type: Boolean,
      default: true,
    },
    hasEditionRole: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pdfLoading: false,
      descargarIcon,
      editarIcon,
      eliminarIcon,
      plusIcon,
      verIcon,
    };
  },
  computed: {
    haveTitle() {
      return this.title != undefined;
    },
    haveIcon() {
      return this.icon != undefined;
    },
  },
  methods: {
    goTest(id, section_id, completed) {
      if (completed) {
        this.$router.push({
          name: "testResult",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "test",
          params: { id, section_id },
        });
      }
    },
    editTest(id) {
      return id;
    },
    deleteTest() {
      this.$emit("showDeleteModal");
    },
    downloadPdf() {
      this.pdfLoading = true;
      axios
        .get(`/tests/pdf/${this.test.id}`, {
          responseType: "blob",
        })
        .then((r) => r.data)
        .then((data) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //create download
          const downloadLink = document.createElement("a");
          const fileName = `Informe AvaluaT - Test ${this.test.name}.pdf`;

          downloadLink.href = fileURL;
          downloadLink.download = fileName;
          downloadLink.click();
          this.pdfLoading = false;
        });
    },
  },
};
</script>
