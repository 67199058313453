<template>
  <header class="flex items-center relative">
    <span
      class="inline-block bg-white py-3 h-11 w-11 rounded-full text-orange-400 fill-current shadow"
    >
      <vc-icon :icon="icon" />
    </span>

    <span class="text-gray-600 text-lg font-normal ml-2">{{
      displayName | camelCase
    }}</span>

    <div class="absolute -top-4 right-0 flex justify-center items-center">
      <span v-if="email" class="text-gray-600 text-sm pr-2">{{ email }}</span>
      <div class="w-10 px-1">
        <button
          class="w-full p-1 text-xs text-white font-semibold hover:bg-orange-400 hover:text-white focus:outline-none uppercase"
          :class="styleLangES"
          @click="changeLang('es')"
        >
          CAS
        </button>
      </div>
      <div class="w-10 px-1">
        <button
          class="p-1 text-xs text-white font-semibold hover:bg-orange-400 hover:text-white focus:outline-none uppercase"
          :class="styleLangVal"
          @click="changeLang('val')"
        >
          VAL
        </button>
      </div>
      <a
        class="ml-3"
        :href="`2.0.1-manual-avaluat-${
          $i18n.locale === 'es' ? 'cas' : 'val'
        }.pdf`"
        download
      >
        <span
          class="inline-block mt-px pt-3 pb-2 h-10 rounded-full text-orange-400 fill-current hover:text-orange-600 transition-duration-100"
        >
          <vc-icon :icon="manualIcon" />
        </span>
      </a>
      <button class="ml-2 focus:outline-none" @click="doLogout">
        <span
          class="inline-block mt-px pt-3 pb-2 h-10 rounded-full text-orange-400 fill-current hover:text-orange-600 transition-duration-100"
        >
          <vc-icon :icon="logoutIcon" />
        </span>
      </button>
    </div>

    <hr class="flex-grow border-gray-500 ml-2 mt-2" />
  </header>
</template>

<script>
import { mapActions } from "vuex";

import { icons, logoutIcon, manualIcon } from "@/assets/data/icons.js";
import axios from "axios"
import axiosAuth from "@/axios-auth";
import axiosBasic from "../axios-basic";
import Icon from "@/components/icon/Icon.vue";


export default {
  filters: {
    camelCase(value) {
      return value[0].toUpperCase() + value.slice(1);
    },
  },
  components: {
    "vc-icon": Icon,
  },
  props: {
    iconName: String,
    displayName: String,
    email: String,
  },
  data() {
    return {
      icons,
      logoutIcon,
      manualIcon,
    };
  },
  computed: {
    icon() {
      return icons.find((icon) => icon.name === this.iconName);
    },
    lang() {
      return this.$i18n.locale;
    },
    styleLangES() {
      return this.lang == "es"
        ? "bg-orange-400 cursor-default"
        : "bg-orange-200 text-orange-500";
    },
    styleLangVal() {
      return this.lang == "val"
        ? "bg-orange-400 cursor-default"
        : "bg-orange-200 text-orange-500";
    },
  },
  created() {
    axiosAuth.defaults.headers.common["lang"] === "val"
      ? this.$moment.locale("ca")
      : this.$moment.locale("es");
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      setTest: "tests/setTest",
      setTestSections: "tests/setTestSections",
    }),
    doLogout() {
      this.logout();
    },
    changeLang(lang) {
      if (this.lang !== lang) {
        window.localStorage.setItem("lang", lang);
        axios.defaults.headers.common["lang"] = lang;
        axiosBasic.defaults.headers.common["lang"] = lang;
        axiosAuth.defaults.headers.common["lang"] = lang;
        this.$i18n.locale = lang;
        lang === "val" ? this.$moment.locale("ca") : this.$moment.locale("es");
        this.$emit("changedLang");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
