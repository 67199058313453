<template>
  <apexchart width="100%" height="400px" type="radialBar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  data() {
    return {
      options: {
        chart: {
          height: 280,
          type: "radialBar"
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "diagonal1",
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [10, 150, 150],
            gradientToColors: ["#d15e15"]
          },
          colors: ["#F28C32"],
          opacity: 1
        },
        plotOptions: {
          radialBar: {
            track: {
              background: "#F7B05E",
              strokeWidth: "40%"
            },
            hollow: {
              margin: 15,
              size: "70%"
            },

            dataLabels: {
              showOn: "always",
              name: {
                show: false
              },
              value: {
                color: "#d15e15",
                fontSize: "5em",
                offsetY: 20,
                formatter: function(val) {
                  // return (val / 10).toFixed(2);
                  return val;
                }
              }
            }
          }
        },
        yaxis: {
          min: 0,
          max: 100
        },

        stroke: {
          lineCap: "round"
        },
        labels: ["Progress"]
      }
    };
  },
  props: {
    categories: Array,
    series: Array
  },
  components: {
    apexchart: VueApexCharts
  }
};
</script>