<template>
  <div class="tab w-full border-b border-orange-600 overflow-hidden">
    <div
      class="flex justify-between items-center cursor-pointer text-orange-600 hover:text-orange-400"
      @click="onToggle"
    >
      <span class="text-2xl font-semibold">{{ data.title }}</span>
      <div
        class="ml-2 w-3 h-3 rotate fill-current transition-duration-250 arrow justify-end"
        :class="{ 'rotate-90': toggle, '-rotate-90': !toggle }"
      >
        <vc-icon :icon="arrowIcon" />
      </div>
    </div>
    <div
      class="tab-content overflow-hidden px-2"
      :class="{ 'max-h-0': !toggle }"
    >
      <AdviceAverage :data="data" />

      <!-- <div v-if="data.subsections">
        <vc-subsection v-for="(advice,index2) in data.subsections" :key="index2" :data="advice"></vc-subsection>
      </div>-->
    </div>
  </div>
</template>

<script>
import { arrowIcon } from "@/assets/data/icons.js";
import Icon from "@/components/icon/Icon.vue";
import AdviceAverage from "@/components/tabs/AdviceAverage.vue";

export default {
  components: {
    vcIcon: Icon,
    AdviceAverage,
  },
  props: {
    title: String,
    data: Object,
    toggled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name,
      arrowIcon,
      toggle: this.toggled,
    };
  },
  computed: {
    maxHeight() {
      return this.toggle ? "max-h-1Griton " : "max-h-0";
    },
  },
  methods: {
    onToggle() {
      this.toggle = !this.toggle;
    },
    getRandom(min, max) {
      return Math.round(Math.random() * (max - min) + min);
    },
  },
};
</script>

<style lang="scss" scoped></style>
