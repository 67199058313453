var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step relative flex flex-col w-full items-center",class:{
    active: _vm.isVisited,
    last: _vm.isLast,
    'pointer-events-none': !_vm.canClick,
  }},[_c('div',{staticClass:"w-2/4 mb-2 flex-grow text-center flex items-center justify-center pb-3"},[_c('span',{staticClass:"text-orange-600 text-xs font-semibold transition-duration-250"},[_vm._v(_vm._s(_vm.step.name))])]),_c('div',{staticClass:"flex flex-row justify-end items-center w-full"},[_vm._m(0),_c('div',{staticClass:"h-6 ball cursor-pointer transition-duration-250",on:{"click":_vm.goSection}},[_c('div',{staticClass:"h-6 w-6 rounded-full bg-orange-600",class:{ 'w-14': _vm.isActive, 'scale-3': _vm.isLast }})]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-1 left-bar"},[_c('div',{staticClass:"h-full bg-orange-600 w-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-1 right-bar"},[_c('div',{staticClass:"h-full bg-orange-600 w-full"})])
}]

export { render, staticRenderFns }