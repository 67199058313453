import ChangePassword from "@/views/ChangePassword.vue";
import Configuracion from "@/views/Configuracion.vue";
import Dashboard from "@/views/Dashboard.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Herramientas from "@/views/Herramientas.vue";
import Informes from "@/views/Informes.vue";
import Login from "@/views/Login.vue";
import NewUser from "@/views/NewUser.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Singup from "@/views/Singup.vue";
import Tareas from "@/views/Tareas.vue";
import Test from "@/views/Test.vue";
import TestDashboard from "@/views/TestDashboard.vue";
import TestNew from "@/views/TestNew.vue";
import TestResult from "@/views/TestResult.vue";

import store from "./store/store";

// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters["auth/isAuthenticated"]) {
//     next();
//     return;
//   }
//   next("/tests");
// };

const ifAuthenticated = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticatedCreateTest = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    if (store.getters["auth/getRoles"].includes(3)) {
      next();
      return;
    } else {
      next("/tests");
      return;
    }
  }

  next("/");
};

const ifAuthenticatedEditTest = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    if (store.getters["auth/getRoles"].includes(4)) {
      next();
      return;
    } else {
      next("/tests");
      return;
    }
  }
  next("/");
};

const ifAuthenticatedSingup = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    store.dispatch["auth/logout"];
  }
  next();
  return;
};

export const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    // beforeEnter: ifNotAuthenticated
  },
  {
    path: "/singup",
    name: "singup",
    component: Singup,
    beforeEnter: ifAuthenticatedSingup,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/tests",
    name: "testDashboard",
    component: TestDashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/tests/new",
    name: "testNew",
    component: TestNew,
    beforeEnter: ifAuthenticatedCreateTest,
  },
  {
    path: "/tests/:id/result",
    name: "testResult",
    component: TestResult,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/tests/:id/:section_id",
    name: "test",
    component: Test,
    beforeEnter: ifAuthenticatedEditTest,
  },
  {
    path: "/informes",
    name: "informes",
    component: Informes,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/configuracion",
    name: "configuracion",
    component: Configuracion,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/herramientas",
    name: "herramientas",
    component: Herramientas,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/tareas",
    name: "tareas",
    component: Tareas,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: ChangePassword,
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/newUser",
    name: "newUser",
    component: NewUser,
    beforeEnter: ifAuthenticatedSingup,
  },
  {
    path: "*",
    component: PageNotFound,
    beforeEnter: ifAuthenticated,
  },
];
