<template>
  <path class="cls-1" :d="data.d" />
</template>

<script>
export default {
  props: {
    data: Object
  }
};
</script>