<template>
  <transition name="fade" tag="div">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h3>{{ titulo }}</h3>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <p>{{ descripcion }}</p>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="flex justify-end">
                <button
                  class="btn-modal-inverted focus:outline-none mx-1 w-24"
                  @click="$emit('close')"
                >
                  {{ $t("common.cancelar") }}
                </button>
                <button
                  class="btn-modal focus:outline-none mx-1 w-24"
                  @click="$emit('confirm')"
                >
                  Eliminar
                </button>
              </div>
              <!-- :disabled="(!testCopy && $v.testName.$invalid) ||  ($v.testName.$invalid || $v.selectedSections.$invalid) || !hasCreationRole" -->
              <!-- <button class="modal-default-button" @click="$emit('confirm')">Ok</button>
              <button class="modal-default-button" @click="$emit('close')">nO</button>-->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    titulo: String,
    descripcion: String,
  },
};
</script>

<style scoped>
.modal-mask {
  @apply fixed w-full h-full top-0 left-0 z-100;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-wrapper {
  @apply flex h-full justify-center;
}

.modal-container {
  @apply p-4 my-auto bg-white rounded border-t-4 border-orange-600;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.modal-header {
  @apply text-orange-600 font-bold text-xl;
}

.modal-body {
  @apply pb-4;
}
.btn-modal,
.btn-modal-inverted {
  @apply bg-orange-600 text-white text-xs py-2 rounded uppercase font-semibold border border-2 border-orange-600 transition-duration-100;
}
.btn-modal-inverted {
  @apply bg-white text-orange-600;
}

.btn-modal:hover {
  @apply bg-orange-400 border-orange-400;
}
.btn-modal-inverted:hover {
  @apply bg-orange-600 text-white;
}

.btn-modal:disabled {
  @apply bg-gray-500 cursor-auto border-gray-600 opacity-25;
}
</style>
