import axios from "../../axios-auth";
import axiosBasic from "../../axios-basic";

export default {
  namespaced: true,
  state: {
    tasks: null,
    states: null,
    priorities: null,
  },
  getters: {
    getTasks: (state) => {
      return state.tasks;
    },
    getPriorities: (state) => {
      return state.priorities;
    },
    getStates: (state) => {
      return state.states;
    },
  },
  actions: {
    setTasks: ({ commit }) => {
      commit("setTasks", null);
      new Promise((resolve, reject) => {
        axios
          .get("/tasks")
          .then((tasks) => {
            commit("setTasks", tasks.data);
            resolve(tasks.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setTasksBasic: ({ commit }) => {
      commit("setTasks", null);
      new Promise((resolve, reject) => {
        axiosBasic
          .get("/tasks")
          .then((tasks) => {
            commit("setTasks", tasks.data);
            resolve(tasks.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    newTask: ({ dispatch, commit }, task) => {
      commit("setTasks", null);
      axios.post("/tasks/", task).then(() => {
        dispatch("setTasks");
      });
    },
    updateTask: ({ dispatch, commit }, task) => {
      commit("setTasks", null);
      delete task.deadlineDate;
      axios.put(`/tasks/${task.idtask}`, task).then(() => {
        dispatch("setTasks");
      });
    },
    deleteTask: ({ dispatch, commit }, idtask) => {
      commit("setTasks", null);
      axios.delete(`/tasks/${idtask}`).then(() => {
        dispatch("setTasks");
      });
    },
  },
  mutations: {
    setTasks: (state, tasks) => {
      state.tasks = tasks;
    },
  },
};
