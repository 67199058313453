<template>
  <div
    ref="box"
    class="w-full my-8 flex-col md:px-4 transition-duration-500"
    :class="[cajaActiva, opacidad]"
  >
    <span
      v-if="haveTitle"
      class="text-orange-600 -top-6 left-0 font-bold pl-16 truncate block w-full"
    >{{title}}</span>
    <div class="relative">
      <span
        class="block absolute shadow w-13 h-13 bg-orange-400 z-50 rounded-full -top-4 py-3 text-white fill-current"
        v-if="haveIcon"
      >
        <vc-icon :icon="icon" />
      </span>
      <span
        class="block absolute w-16 h-16 bg-gray-200 z-40 rounded-full -top-6 -left-2"
        v-if="haveIcon"
      ></span>

      <div class="bg-white pt-12 pb-8 px-10 rounded h-full relative shadow">
        <div class="absolute bg-gray-200 z-10 w-4 h-24 -top-8 -left-4"></div>
        <div class="absolute bg-gray-200 z-10 w-20 h-8 -top-8 -left-4"></div>
        <div
          class="absolute border-6 border-orange-400 rounded-full w-20 h-20 bg-gray-200 -top-8 -left-4"
          v-if="haveIcon"
        ></div>
        <div class="w-full h-full">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./icon/Icon.vue";

export default {
  props: {
    icon: Object,
    title: {
      type: String,
      default: undefined
    },
    enable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    haveTitle() {
      return this.title != undefined;
    },
    haveIcon() {
      return this.icon != undefined;
    },
    cajaActiva() {
      return this.enable ? "box-enable" : "box-disable";
    },
    opacidad() {
      return this.visible ? "opacity-100" : "opacity-0";
    }
  },
  components: {
    "vc-icon": Icon
  },
  data: function() {
    return {
      visible: false
    };
  },
  mounted: function() {
    this.$nextTick(function() {
      this.visible = true;
    });
  }
};
</script>
