<template>
  <div v-bar class="w-full vb ml-20">
    <div class="scroll-smoth">
      <div class="wrapper flex flex-col">
        <vc-header
          icon-name="tests"
          :display-name="'test'"
          :email="getUserEmail"
          @changedLang="refreshReq"
        />
        <div v-if="!loaded" class="text-center">
          <span class="text-orange-400 text-lg">{{
            $t("common.cargando")
          }}</span>
        </div>
        <div
          v-if="getTest && getTestSections && getEnterprise && loaded"
          class="flex flex-col"
        >
          <div class="bg-white pb-4 rounded mt-4">
            <div class="steps w-full p-10 pt-8 bg-orange-200">
              <h1 class="text-xl text-orange-600 font-bold text-center">
                {{ getEnterprise.name }} - {{ getTest.name }}
              </h1>
              <div class="flex mt-6">
                <vc-step
                  v-for="(section, index) in getTestSections"
                  :key="index"
                  :step="section"
                  :is-last="section.id == getTest.idsection"
                  :is-active="isActiveSection(index)"
                  :is-visited="isVisitedSection(section.id, index)"
                  :can-click="isActiveStep(index)"
                  :can-save="allQuestionAnswered"
                  @saveTest="saveTest"
                />
              </div>
            </div>
            <div class="questions wrapper mx-auto w-4/6">
              <vc-navigation-question
                :disabled="!allQuestionAnswered"
                :is-last-section="isLastSection"
                :is-first-section="isFirstSection"
                @nextSection="nextSection"
                @prevSection="prevSection"
                @finishTest="finishTest"
              />
              <vc-legend />
              <vc-question
                v-for="(question, index) in getTest.questions"
                :key="index"
                :index="question.id"
                :data="question"
                :answers="getTest.answers"
              />
              <vc-navigation-question
                :disabled="!allQuestionAnswered"
                :is-last-section="isLastSection"
                :is-first-section="isFirstSection"
                @nextSection="nextSection"
                @prevSection="prevSection"
                @finishTest="finishTest"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Header from "@/components/Header.vue";
import Legend from "@/components/questions/Legend.vue";
import NavigationQuestion from "@/components/questions/NavigationQuestion.vue";
import Question from "@/components/questions/Question.vue";
import Step from "@/components/questions/Step.vue";

export default {
  data() {
    return {
      name: "tests",
      loading: false,
      currentIndex: -1,
      idCurrentTest: null,
      lastVisited: -1,
      loaded: false,
    };
  },
  created() {
    this.idTest = this.$route.params.id;
    this.setTest({
      idTest: this.$route.params.id,
      idSection: this.$route.params.section_id,
    }).then(() => this.setTestSections(this.$route.params.id));
    if (!this.getEnterprise) {
      this.setEnterprise();
    }
  },
  computed: {
    ...mapGetters({
      getEnterprise: "enterprise/getEnterprise",
      getUserEmail: "auth/userEmail",
      getTest: "tests/getTest",
      getSavedTest: "tests/getSavedTest",
      getTestSections: "tests/getTestSections",
    }),
    isLastSection() {
      return this.currentIndex === this.getTestSections.length - 1;
    },
    isFirstSection() {
      return this.currentIndex === 0;
    },
    allQuestionAnswered() {
      return (
        this.getTest.questions.filter((question) => {
          if (question.hasSubquestions) {
            const subanswered = question.subquestions.filter(
              (sub) => sub.answer == 0
            );
            return subanswered.length == 0 ? null : subanswered;
          } else {
            return question.answer == 0;
          }
        }).length <= 0
      );
    },
  },
  watch: {
    haveSubquestions() {
      return this.getTest.questions.subquestions > 0;
    },
    $route() {
      this.setTest({
        idTest: this.$route.params.id,
        idSection: this.$route.params.section_id,
      });
      // if (!this.getTestSections) {
      //   this.setTestSections(this.$route.params.id);
      // }
    },
    getTest(newValue) {
      if (newValue) {
        this.loaded = true;
        this.scrollToTop();
        this.loading = false;
      }
    },
  },
  methods: {
    ...mapActions({
      setEnterprise: "enterprise/setEnterprise",
      setTest: "tests/setTest",
      setSavedTest: "tests/setSavedTest",
      setTestSections: "tests/setTestSections",
      updateTestSectionAsync: "tests/updateTestSectionAsync",
    }),
    isActiveStep(indexStep) {
      const { current } = this.getTest;
      const indexLast = this.getTestSections.findIndex(
        (testSection) => testSection.id === current
      );
      return (
        indexStep < indexLast ||
        (indexStep === indexLast && this.currentIndex != indexLast)
      );
    },
    scrollToTop() {
      document.getElementsByClassName("vb-content")[0].scrollTo(0, 0);
    },
    isActiveSection(index) {
      return this.currentIndex == index;
    },
    isVisitedSection(id, index) {
      if (this.getTest.idsection == id) {
        this.currentIndex = index;
      }

      return index <= this.currentIndex || this.currentIndex == -1;
    },

    saveTest() {
      const idTest = this.$route.params.id,
        idSection = this.$route.params.section_id,
        test = this.getTest;

      return this.updateTestSectionAsync({ idTest, idSection, test });
    },
    nextSection() {
      if (this.currentIndex != -1 && !this.isLastSection && !this.loading) {
        this.loaded = false;
        this.loading = true;

        this.saveTest().then((data) => {
          if (data === true) {
            const nextSection = this.getTestSections[++this.currentIndex].id,
              testId = this.$route.params.id;

            this.$router.push({
              name: "test",
              params: { id: testId, section_id: nextSection },
            });
          }
        });
      }
    },
    prevSection() {
      if (this.currentIndex != -1 && !this.isFirstSection && !this.loading) {
        this.loaded = false;
        this.loading = true;
        // this.saveTest().then((data) => {
        //   if (data === true) {
        const prevSection = this.getTestSections[--this.currentIndex].id,
          testId = this.$route.params.id;
        this.$router.push({
          name: "test",
          params: { id: testId, section_id: prevSection },
        });
        //   }
        // });
      }
    },
    finishTest() {
      this.saveTest().then((data) => {
        if (data === true) {
          const testId = this.$route.params.id;

          this.$router.push({
            name: "testResult",
            params: { id: testId },
          });
        }
      });
    },
    refreshReq() {
      this.loaded = false;
      this.setTest({
        idTest: this.$route.params.id,
        idSection: this.$route.params.section_id,
      }).then(() => this.setTestSections(this.$route.params.id));
    },
  },
  components: {
    vcHeader: Header,
    vcQuestion: Question,
    vcStep: Step,
    vcNavigationQuestion: NavigationQuestion,
    vcLegend: Legend,
  },
};
</script>
