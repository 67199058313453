<template>
  <div
    class="step relative flex flex-col w-full items-center"
    :class="{
      active: isVisited,
      last: isLast,
      'pointer-events-none': !canClick,
    }"
  >
    <div
      class="w-2/4 mb-2 flex-grow text-center flex items-center justify-center pb-3"
    >
      <span
        class="text-orange-600 text-xs font-semibold transition-duration-250"
        >{{ step.name }}</span
      >
    </div>
    <div class="flex flex-row justify-end items-center w-full">
      <div class="w-full h-1 left-bar">
        <div class="h-full bg-orange-600 w-full" />
      </div>
      <div
        class="h-6 ball cursor-pointer transition-duration-250"
        @click="goSection"
      >
        <div
          class="h-6 w-6 rounded-full bg-orange-600"
          :class="{ 'w-14': isActive, 'scale-3': isLast }"
        />
      </div>
      <div class="w-full h-1 right-bar">
        <div class="h-full bg-orange-600 w-full" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: Object,
    isActive: Boolean,
    isVisited: Boolean,
    isLast: Boolean,
    canClick: Boolean,
    canSave: Boolean,
  },
  methods: {
    goSection() {
      const idTest = this.$route.params.id,
        idSection = this.step.id,
        idSectionUrl = this.$route.params.section_id;
      if (idSection != idSectionUrl) {
        this.canSave && this.$emit("saveTest");
        this.$router.push({
          name: "test",
          params: { id: idTest, section_id: idSection },
        });
      }
    },
  },
};
</script>
