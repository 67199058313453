import { render, staticRenderFns } from "./NavigationQuestion.vue?vue&type=template&id=8442cdc0&scoped=true&"
import script from "./NavigationQuestion.vue?vue&type=script&lang=js&"
export * from "./NavigationQuestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8442cdc0",
  null
  
)

export default component.exports