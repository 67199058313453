import "./assets/css/tailwind.css";
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import axios from "axios";
import store from "@/store/store";
import { routes } from "./routes.js";
import i18n from "./i18n";
import Vuebar from "vuebar";
import VTooltip from "v-tooltip";
import Toasted from "vue-toasted";
import Vuelidate from "vuelidate";

import config from "./config";

const moment = require("moment");
require("moment/locale/es");
require("moment/locale/ca");

Vue.use(require("vue-moment"), { moment });
Vue.use(VueRouter);
Vue.use(Vuebar);
Vue.use(VTooltip);
Vue.use(Toasted);
Vue.use(Vuelidate);

const router = new VueRouter({
  base: "/",
  mode: "history",
  routes,
});

axios.defaults.baseURL = config.URL_SERVER;
axios.defaults.headers.common["lang"] = i18n.locale;

new Vue({
  el: "#app",
  router,
  i18n,
  store,
  render: (h) => h(App),
});
