<template>
  <div v-bar class="w-full vb ml-20">
    <div>
      <div class="wrapper flex flex-col">
        <vc-header
          :icon-name="name"
          display-name="test"
          :email="getUserEmail"
        />
        <div v-if="!loaded" class="text-center">
          <span class="text-orange-400 text-lg">{{
            $t("common.cargando")
          }}</span>
        </div>
        <div v-if="tests && loaded" class="flex flex-col">
          <p class="mt-2 text-gray-600">
            Nota: {{ $t("testDashboard.disclaimer") }}.
          </p>
          <vc-section-separator :name="$t('testDashboard.activo')">
            <div
              v-if="hasCreationRole"
              id="new-test"
              class="w-5 h-5 mx-2 self-center"
            >
              <button
                v-tooltip="$t('testDashboard.nuevoTest')"
                class="focus:outline-none w-full h-full"
                @click="newTest"
              >
                <span
                  class="rounded-full text-orange-400 fill-current hover:text-orange-600 transition-duration-100"
                >
                  <vc-icon :icon="plusIcon" />
                </span>
              </button>
            </div>
          </vc-section-separator>
          <div class="relative">
            <transition-group
              tag="div"
              name="fade"
              class="w-full flex flex-wrap"
            >
              <div
                v-for="enabledTest in getEnabledTests"
                :key="enabledTest.id"
                class="w-full md:w-1/2 xl:w-1/3"
              >
                <vc-box-test
                  :icon="findIcon(name)"
                  :title="enabledTest.name"
                  :test="enabledTest"
                  :has-delete-role="hasCreationRole"
                  :has-edition-role="hasEditionRole"
                  :enable="true"
                  @showDeleteModal="onShowDeleteModal(enabledTest)"
                />
              </div>
            </transition-group>
          </div>
          <Modal
            v-if="showModal"
            :titulo="$t('testDashboard.borrarTest')"
            :descripcion="$t('testDashboard.eliminarTest')"
            @confirm="
              deleteSelectedTest();
              showModal = false;
            "
            @close="showModal = false"
          />
          <vc-section-separator :name="$t('testDashboard.historico')" />
          <transition-group tag="div" name="fade" class="w-full flex flex-wrap">
            <div
              v-for="disabledTest in getDisabledTests"
              :key="disabledTest.id"
              class="w-full md:w-1/2 xl:w-1/3"
            >
              <!-- :hasEditionRole="hasEditionRole" -->
              <vc-box-test
                :icon="findIcon(name)"
                :title="disabledTest.name"
                :test="disabledTest"
                :has-delete-role="hasCreationRole"
                :enable="false"
                @showDeleteModal="onShowDeleteModal(disabledTest)"
              />
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  descargarIcon,
  editarIcon,
  eliminarIcon,
  icons,
  plusIcon,
  verIcon,
} from "@/assets/data/icons.js";
import BoxTest from "@/components/BoxTest.vue";
import Header from "@/components/Header.vue";
import Icon from "@/components/icon/Icon.vue";
import Modal from "@/components/Modal.vue";
import SectionSeparator from "@/components/SectionSeparator";

export default {
  components: {
    vcHeader: Header,
    vcSectionSeparator: SectionSeparator,
    vcBoxTest: BoxTest,
    vcIcon: Icon,
    Modal,
  },
  data() {
    return {
      loaded: false,
      name: "tests",
      percentage: 0,
      descargarIcon,
      editarIcon,
      eliminarIcon,
      plusIcon,
      verIcon,
      date: Date.now(),
      tests: [],
      neededCreationRole: 3,
      neededEditionRole: 4,
      showModal: false,
      selectedTest: -1,
    };
  },
  created() {
    this.setTests().then(() => this.setTestValues());
  },
  computed: {
    ...mapGetters({
      getUserEmail: "auth/userEmail",
      getTests: "tests/getTests",
      getUserRoles: "auth/getRoles",
    }),
    getEnabledTests() {
      return this.tests.filter((test) => test.completed == false);
    },
    getDisabledTests() {
      return this.tests.filter((test) => test.completed == true);
    },
    existEnabledTests() {
      return this.getEnabledTests.length > 0;
    },
    hasCreationRole() {
      if (this.getUserRoles) {
        return this.getUserRoles.includes(this.neededCreationRole);
      } else {
        return false;
      }
    },
    hasEditionRole() {
      if (this.getUserRoles) {
        return this.getUserRoles.includes(this.neededEditionRole);
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      setTests: "tests/setTests",
      updateTest: "tests/updateTest",
      deleteTest: "tests/deleteTest",
    }),
    setTestValues() {
      this.tests = this.getTests;
    },
    onShowDeleteModal(test) {
      this.selectedTest = test;
      this.showModal = true;
    },
    deleteSelectedTest() {
      this.deleteTest(this.selectedTest.id);
      const position = this.tests.indexOf(this.selectedTest);
      this.tests.splice(position, 1);
    },
    findIcon(value) {
      return icons.find((icon) => icon.name == value);
    },
    changelang() {
      this.$i18n.locale = "en";
    },
    goTest(id, section_id, completed) {
      if (completed) {
        this.$router.push({
          name: "testResult",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "test",
          params: { id, section_id },
        });
      }
    },
    editTest(id) {
      return id;
    },
    downloadTest(id) {
      return id;
    },
    newTest() {
      this.$router.push({ name: "testNew" });
    },
  },
  watch: {
    getTests(newTestValue) {
      if (newTestValue) {
        this.loaded = true;
        this.setTestValues();
      }
    },
  },
};
</script>

<style></style>
