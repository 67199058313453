import Vue from "vue";
import VueI18n from "vue-i18n";

import es from "./locales/es.json";
import val from "./locales/val.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "val",
  fallbackLocale: "val",
  messages: {
    val,
    es,
  },
});
