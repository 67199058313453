<template>
  <div v-bar class="w-full ml-20 vb">
    <div>
      <div class="wrapper">
        <vc-header
          :display-name="$t('tareas.tareas')"
          :icon-name="name"
          :email="getUserEmail"
          @changedLang="refreshReq"
        />
        <div v-if="!getTasks" class="text-center">
          <span class="text-orange-400 text-lg">{{
            $t("common.cargando")
          }}</span>
        </div>
        <div v-if="!existingTests && !!getTasks">
          <div class="flex text-center justify-center items-center flex-col">
            <h2 class="text-gray-500 text-2xl font-bold">
              {{ $t("tareas.noTestCompletados") }}
            </h2>
            <span class="text-gray-600">{{
              $t("tareas.noTestCompletadosText")
            }}</span>
          </div>
        </div>
        <div v-if="existingTests && !!getTasks">
          <Modal
            v-if="showModal"
            :titulo="$t('tareas.eliminarTarea')"
            :descripcion="$t('tareas.descripcionEliminarTarea')"
            @close="showModal = false"
            @confirm="
              deleteSelectedTask();
              showModal = false;
            "
          />
          <sectionSeparator :name="$t('tareas.tareas') | firstCapital">
            <div v-if="hasTaskRole" class="w-5 h-5 mx-2 self-center">
              <button
                v-tooltip="$t('tareas.nuevaTarea')"
                class="focus:outline-none w-full h-full"
                :disabled="!existingTests || editingTask"
                @click="newTask"
              >
                <span :class="styleIconAddTask">
                  <Icon :icon="plusIcon" />
                </span>
              </button>
            </div>
            <!-- //Filtro tareas completadas -->
            <div class="self-center ml-8">
              <input
                id="filtroTareas"
                v-model="mostrarCompletadas"
                type="checkbox"
              />
              <label class="text-orange-400 text-xs" for="filtroTareas">{{
                $t("tareas.mostrarCompletadas")
              }}</label>
            </div>
          </sectionSeparator>
          <transition-group
            v-if="listaTareas"
            tag="div"
            name="fade"
            class="w-full flex flex-wrap"
          >
            <div
              v-for="task in listaTareas.slice().reverse()"
              :key="task.idtask"
              class="w-full md:w-1/2"
            >
              <boxTarea
                :task="task"
                :show-buttons="hasTaskRole"
                :editable="editable"
                :users-array="usersArray"
                :users-array-all="usersArrayAll"
                @deleteTask="onDeleteTask(task)"
                @updateTask="onSaveTask(task)"
                @editingTask="onEditingTasks()"
                @cancelTask="onCancelTask(task)"
              />
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { plusIcon } from "@/assets/data/icons.js";
import BoxTarea from "@/components/BoxTarea.vue";
import Header from "@/components/Header.vue";
import Icon from "@/components/icon/Icon.vue";
import Modal from "@/components/Modal.vue";
import SectionSeparator from "@/components/SectionSeparator.vue";

export default {
  name: "Tareas",
  data() {
    return {
      name: "tareas",
      plusIcon,
      editingTask: false,
      taskList: [],
      neededTaskRole: 5,
      mostrarCompletadas: true,
      showModal: false,
      selectedTask: null,
      usersArray: [],
      usersArrayAll: [],
      authUser: null,
    };
  },
  created() {
    this.setTasks().then(() => this.setTasksValues());
    this.setUsers();
    if (!this.getTests) {
      this.setTests();
    } else {
      this.setTests();
    }
  },
  computed: {
    ...mapGetters({
      getUserEmail: "auth/userEmail",
      getTasks: "tasks/getTasks",
      getUsers: "users/getTasksUsers",
      getUsersAll: "users/getUsers",
      getUserRoles: "auth/getRoles",
      getTests: "tests/getTests",
    }),
    styleIconAddTask() {
      return this.editingTask || !this.existingTests
        ? "rounded-full text-gray-500 fill-current transition-duration-100"
        : "rounded-full text-orange-400 fill-current hover:text-orange-600 transition-duration-100";
    },
    listaTareas() {
      return this.taskList && this.taskList.length > 0
        ? this.mostrarCompletadas
          ? this.taskList
          : this.taskList.filter((task) => task.state != 1)
        : null;
    },
    editable() {
      return !this.editingTask;
    },
    hasTaskRole() {
      if (this.getUserRoles) {
        return this.getUserRoles.includes(this.neededTaskRole);
      } else {
        return false;
      }
    },
    existingTests() {
      return this.getTests && this.getTests.length ? true : false;
    },
  },
  methods: {
    ...mapActions({
      setTests: "tests/setTestsBasic",
      setTasks: "tasks/setTasksBasic",
      setUsers: "users/setUsers",
      deleteTask: "tasks/deleteTask",
      createTask: "tasks/newTask",
      updateTask: "tasks/updateTask",
      setTestSectionQuestionAsync: "tests/setTestSectionQuestionAsync",
    }),
    setTasksValues() {
      this.taskList = [];
      this.taskList = this.getTasks;
    },
    newTask() {
      this.setTestSectionQuestionAsync().then((result) => {
        this.taskList.push({
          idtask: 0,
          state: 0,
          priority: 0,
          description: "",
          idtest: result[0],
          idsection: result[1],
          idquestion: result[2],
          createdBy: this.authUser.id,
          assignedTo: this.authUser.id,
          new: true,
        });
      });
      this.editingTask = true;
    },
    deleteSelectedTask() {
      if (!this.selectedTask.new) {
        this.deleteTask(this.selectedTask.idtask);
      }
      const position = this.taskList.indexOf(this.selectedTask);
      this.taskList.splice(position, 1);
      this.editingTask = false;
    },
    onDeleteTask(task) {
      this.selectedTask = task;
      this.showModal = true;
    },
    onSaveTask(task) {
      if (task.new) {
        task.new = false;
        this.createTask(task);
        this.$toasted.show("Tarea creada", {
          position: "bottom-right",
          duration: 2500,
        });
      } else {
        this.updateTask(task);
      }

      const position = this.taskList.indexOf(task);
      this.taskList[position] = task;

      this.editingTask = false;
    },
    onEditingTasks() {
      this.editingTask = true;
    },
    onCancelTask(task) {
      if (task.new) {
        const position = this.taskList.indexOf(task);
        this.taskList.splice(position, 1);
      }
      this.editingTask = false;
      this.setTasks();
    },
    refreshReq() {
      this.taskList = [];
      this.setTasks().then(() => this.setTasksValues());
    },
  },
  filters: {
    firstCapital(val) {
      return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
    },
  },
  watch: {
    getTasks(newTaskValue) {
      if (newTaskValue) {
        this.setTasksValues();
      }
    },
    getUsers(newValue) {
      if (newValue) {
        this.authUser = newValue.find(
          (user) => user.email === this.getUserEmail
        );
        this.usersArray = newValue.map((user) => ({
          name: user.name,
          value: user.id,
        }));
      }
    },
    getUsersAll(newValue) {
      if (newValue) {
        this.usersArrayAll = newValue.map((user) => ({
          name: user.name,
          value: user.id,
        }));
      }
    },
  },
  components: {
    "vc-header": Header,
    SectionSeparator,
    Icon,
    BoxTarea,
    Modal,
  },
};
</script>

<style></style>
