import axios from "../../axios-auth";

export default {
  namespaced: true,
  state: {
    roles: null,
  },
  getters: {
    getRoles: (state) => {
      return state.roles;
    },
  },
  actions: {
    setRoles: ({ commit }) => {
      commit("setRoles", null);
      axios
        .get("/roles")
        .then((r) => r.data)
        .then((roles) => {
          commit("setRoles", roles);
        });
    },
  },
  mutations: {
    setRoles: (state, roles) => {
      state.roles = roles;
    },
  },
};
