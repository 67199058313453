<template>
  <button class="btn-rounded focus:outline-none" :disabled="disabled" @click="$emit('click')">
    <div>
      <vc-icon :icon="icon"></vc-icon>
    </div>
  </button>
</template>

<script>
import Icon from "@/components/icon/Icon.vue";
export default {
  props: {
    icon: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    vcIcon: Icon
  }
};
</script>