<template>
  <div class="w-full bg-csirt">
    <div class="container mx-auto h-full flex justify-center items-center">
      <div class="w-3/5 flex relative">
        <div class="form-background-rotate" />
        <div class="bg-white shadow-md z-20 px-10 py-5 m-auto w-3/4 h-56">
          <h2 class="text-orange-600 text-3xl font-bold leading-normal">
            {{ $t("configuracion.nuevoUsuario") }}
          </h2>
          <div class="mt-5">
            <p>{{ $t("configuracion.email") }}.</p>
            <br />
            <p>{{ $t("common.redirect") }}.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewUser",
  mounted() {
    setTimeout(() => {
      this.$router.push({ name: "login" });
    }, 10000);
  },
};
</script>

<style></style>
