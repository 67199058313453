<template>
  <div class="h-screen flex bg-gray-200">
    <vc-navigation v-if="excluded" />
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Navigation from "@/components/Navigation.vue";

export default {
  name: "App",
  data() {
    return {
      excludedRoutes: [
        "login",
        "singup",
        "changepassword",
        "forgotPassword",
        "newUser",
      ],
    };
  },
  computed: {
    ...mapGetters({
      hasToken: "auth/hasToken",
      getExpirationDate: "auth/getExpirationDate",
    }),
    excluded() {
      return this.excludedRoutes.indexOf(this.$route.name) == -1;
    },
  },
  components: {
    "vc-navigation": Navigation,
  },
  methods: {
    ...mapActions({
      logoutTimeout: "auth/logoutTimeout",
    }),
  },
  watch: {
    hasToken(newTokenValue) {
      if (!newTokenValue) {
        this.$router.push({ name: "login" });
      }
    },
  },
  created() {
    if (this.getExpirationDate > 0) {
      const timeLeft = this.getExpirationDate - new Date().valueOf();
      this.logoutTimeout(timeLeft);
    }
  },
};
</script>

<style scoped>
.slide-enter-active {
  animation: slide-in 200ms ease-out forwards;
}
.slide-leave-active {
  animation: slide-out 200ms ease-out forwards;
}
@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
