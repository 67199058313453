<template>
  <div>
    <div id="container" class="my-4 flex flex-col">
      <div
        id="refresh"
        class="cursor-pointer bg-orange-400 text-white text-center"
        @click="refresh"
      >
        {{ $t("captcha.actualizar") }}
      </div>
      <div id="cap" class="flex items-center justify-center">
        <img
          :src="image"
          class="table"
          id="canvas"
          width="250"
          height="100px"
          alt="Captcha"
        />
      </div>
      <div id="form" class="text-center bg-gray-200 pb-4">
        <p id="ctitle">{{ $t("captcha.introduce") }}</p>
        <div class="flex justify-center items-center">
          <input
            :disabled="refreshing || validated"
            ref="inputCanvas"
            autocomplete="off"
            id="input"
            type="text"
            maxlength="4"
            name="input"
            class="form-control h-8 w-32 py-2 px-4 border border-black rounded-tl-sm rounded-bl-sm text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none disabled:bg-gray-300"
            :class="{
              'border-2 border-orange-400 bg-orange-400 text-white ': validated,
              'border-r-0': !validated,
            }"
            required
          />
          <button
            :disabled="refreshing || validated"
            @click="validate"
            class="py-1 px-2 text-white disabled:bg-gray-300 focus:outline-none"
            id="submit"
            value="Submit"
            type="submit"
            :class="{
              'bg-orange-400': !validated,
              'bg-orange-300 cursor-not-allowed': validated,
            }"
            required
          >
            Validar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      image: "",
      csrfToken: "",
      validated: false,
      refreshing: false,
    };
  },
  mounted() {
    this.resetCanvas();
  },
  methods: {
    async resetCanvas() {
      const resp = await axios.post("/generate-captcha");
      this.image = resp.data.image;
      this.csrfToken = resp.data.csrfToken;
    },

    refresh() {
      this.refreshing = true;
      this.resetCanvas();
      this.refreshing = false;
    },

    restart() {
      this.validated = false;
      this.$refs.inputCanvas.value = "";
      this.refresh();
      this.$emit("validated", this.validated);
    },

    //This checks if the numbers that were entered into the form are correct and displays the correct message.  If correct, the canvas is automatically refreshed.
    async validate() {
      const resp = await axios.post("/check-captcha", {
        _csrf: this.csrfToken,
        captcha: this.$refs.inputCanvas.value,
      });

      if (resp.data.isValid) {
        this.validated = true;
        this.$emit("validated", this.validated);
      } else {
        this.refresh();
        this.$refs.inputCanvas.value = "";
      }
    },

    getCsrfToken() {
      return this.csrfToken;
    },

    getValue() {
      return this.$refs.inputCanvas.value;
    },
  },
};
</script>
