import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";
import enterprise from "./modules/enterprise";
import roles from "./modules/roles";
import tests from "./modules/tests";
import auth from "./modules/auth";
import tasks from "./modules/tasks";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    enterprise,
    roles,
    tests,
    auth,
    tasks
  }
});
