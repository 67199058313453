<template>
  <!-- //Tasks -->
  <div id="adviceTask" class="advice-point">
    <!-- {{data}} -->
    <div class="py-5">
      <p>{{mejorasTitle | cleanHtml}}</p>
      <div class="relative w-full flex flex-col">
        <div v-for="(listaTareas, index) in data.tasks" :key="listaTareas.id + index" class="my-3">
          <div v-if="listaTareas.tasks && listaTareas.tasks.length > 0">
            <span class="text-l font-semibold">{{listaTareas.name}}</span>
            <div
              class="w-full min-h-40 my-3 flex"
              v-for="(tarea, ind) in listaTareas.tasks"
              :key="tarea.id + ind"
            >
              <div class="w-20 flex-col-center">
                <div class="h-11 w-11 mx-auto flex-col-center text-center bg-orange-400 rounded-sm">
                  <span class="text-l font-semibold">{{ind + 1}}</span>
                </div>
              </div>
              <div class="w-full p-4 flex-col-center bg-gray-200 rounded">
                <span class="text-l font-semibold">{{tarea.task}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdviceTask",
  props: {
    data: Object,
    content: String
  },
  computed: {
    mejorasTitle() {
      return this.content;
    }
  },
  filters: {
    cleanHtml: function(value) {
      if (!value) return "";
      value = value.toString();
      let first = value.indexOf(">") + 1;
      let next = value.indexOf("</");
      return value.substring(first, next);
    }
  }
};
</script>