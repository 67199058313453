<template>
  <div
    ref="boxUser"
    class="relative w-full my-8 flex-col md:px-4"
    :class="[cajaActiva, opacidad, isAdminStyle]"
  >
    <span
      v-if="isAdmin"
      class="absolute text-orange-600 -top-6 left-0 font-bold pl-16 md:pl-20 truncate block w-full"
      >Administrador</span
    >
    <div class="relative">
      <span
        class="iconUser block absolute shadow w-13 h-13 bg-orange-400 z-50 rounded-full -top-4 py-3 text-white fill-current transition-duration-500"
      >
        <vc-icon :icon="userIcon" />
      </span>
      <span
        class="block absolute w-16 h-16 bg-gray-200 z-40 rounded-full -top-6 -left-2"
      />

      <div
        class="borderBox bg-white pt-12 pb-8 px-10 rounded h-full overflow-hidden relative shadow border-b-6 transition-duration-500"
      >
        <div
          class="absolute border-6 border-orange-400 rounded-full w-20 h-20 bg-gray-200 -top-8 -left-4"
        />
        <div class="w-full h-full">
          <!--  -->
          <button
            v-if="
              showButtons &&
                !isAdmin &&
                !isOwner &&
                (!enabled || !!user.new) &&
                habilitado
            "
            v-tooltip="$t('configuracion.eliminarUsuario')"
            class="absolute w-6 top-0 right-0 m-2 focus:outline-none"
            @click="deleteUser"
          >
            <span
              class="rounded-full text-orange-400 fill-current hover:text-orange-600"
            >
              <vc-icon :icon="plusIcon" class="rotate-45" />
            </span>
          </button>

          <!-- {{$t('configuracion.eliminarUsuario')}} -->
          <!-- Campos -->
          <div class="flex">
            <div class="w-1/2">
              <span>{{ $t("configuracion.permisos") }}:</span>
              <div
                v-for="(rol, indexRol) in getRoles"
                :key="indexRol"
                class="my-3 flex items-center"
              >
                <input
                  :id="`cbox${user.id}-${indexRol}`"
                  v-model="user.roles"
                  type="checkbox"
                  :value="rol.id"
                  :class="{ 'pointer-events-none': isAdmin || isOwner }"
                />
                <label
                  class="text-orange-400 text-xs"
                  :class="{ 'pointer-events-none': isAdmin || isOwner }"
                  :for="`cbox${user.id}-${indexRol}`"
                  >{{ rol.role }}</label
                >
              </div>
            </div>
            <div class="w-1/2">
              <div class="mb-4">
                <label for="nombre" class="clearfix"
                  >{{ $t("common.nombre") | firstCapital }}:</label
                >
                <div class="pl-4">
                  <input
                    v-model="user.name"
                    type="text"
                    class="form-control w-full px-1 block border-b text-sm placeholder-orange-300 focus:placeholder-orange-500 border-orange-400 focus:outline-none"
                    :placeholder="$t('common.nombre') | firstCapital"
                    @input="$v.user.name.$touch()"
                  />
                </div>
              </div>
              <div v-if="$v.user.name.$invalid" class="error text-xs">
                {{
                  $t("error.errorCampoLargo", {
                    campo: $t("common.nombre"),
                    length: $v.user.name.$params.maxLength.max,
                  })
                }}
              </div>

              <div
                class="mb-4"
                :class="{ inputInvalido: $v.user.email.$invalid }"
              >
                <label for="email" class="clearfix"
                  >{{ $t("common.email") | firstCapital }}:</label
                >
                <div class="pl-4">
                  <input
                    v-model="user.email"
                    type="email"
                    class="form-control w-full mt-2 px-1 block border-b text-sm placeholder-orange-300 focus:placeholder-orange-500 focus:outline-none"
                    :class="{
                      'border-orange-400': user.new,
                      'pointer-events-none border-gray-600': !user.new,
                    }"
                    :placeholder="$t('common.email') | firstCapital"
                    @input="
                      delayTouch($v.user.email);
                      checkEmailOnUse();
                    "
                  />
                </div>
              </div>
              <div v-if="errorEmail" class="error text-xs">
                {{ errorEmail }}
              </div>
              <div v-if="$v.user.email.$error" class="error text-xs">
                {{ $t("error.errorEmail") }}
              </div>
            </div>
          </div>
          <!-- Boton Editar / Guardar -->
          <div v-if="showButtons" class="flex w-full justify-end mt-6">
            <div
              class="overflow-hidden transition-duration-500 whitespace-nowrap"
              :class="divExpanded"
            >
              <vc-btn-simple
                :name="editarGuardar"
                style-wrap="text-white bg-orange-600 py-1 uppercase text-xs font-semibold rounded whitespace-nowrap w-20 border-2 border-orange-600"
                disable-style="text-white bg-gray-500 py-1 uppercase text-xs font-semibold rounded whitespace-nowrap w-20  border-2 border-gray-500"
                :disabled="!habilitado"
                :invalid="
                  $v.user.email.$invalid ||
                  $v.user.name.$invalid ||
                  user.hasError
                "
                @click="onUpdateUser"
              />
              <vc-btn-simple
                :name="$t('common.cancelar')"
                style-wrap="text-orange-600 bg-white uppercase text-xs font-semibold rounded whitespace-nowrap border-orange-600 ml-2 w-20 py-1 border-2"
                @click="cancelUser"
              />
            </div>
          </div>
          <div v-if="user.error" class="error text-xs">
            *No se ha guardado el usuario porque el email ya esta en uso.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, maxLength, required } from "vuelidate/lib/validators";
// import BtnDouble from "@/components/BtnDouble.vue";
import { mapActions, mapGetters } from "vuex";

import { plusIcon, userIcon } from "@/assets/data/icons.js";
import BtnSimple from "@/components/BtnSimple.vue";

import Icon from "./icon/Icon.vue";

const touchMap = new WeakMap();

export default {
  name: "BoxUser",
  props: {
    title: {
      type: String,
      default: undefined,
    },
    user: Object,
    showButtons: {
      type: Boolean,
      default: false,
    },
    editable: {
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getRoles: "roles/getRoles",
      getUserEmail: "auth/userEmail",
    }),
    cajaActiva() {
      return this.enabled || this.user.new ? "box-enable" : "box-disable";
    },
    opacidad() {
      return this.visible ? "opacity-100" : "opacity-0";
    },
    isAdminStyle() {
      return this.isAdmin || this.isOwner ? "box-Admin" : "";
    },
    editarGuardar() {
      return this.enabled || this.user.new
        ? this.$t("common.guardar")
        : this.$t("common.editar");
    },
    divExpanded() {
      return this.enabled || this.user.new ? "w-45" : "w-20";
    },
    habilitado() {
      if (
        this.user.new ||
        (this.enabled &&
          !this.editable &&
          (!this.isAdmin || (this.isAdmin && this.isOwner))) ||
        (!this.enabled &&
          this.editable &&
          (!this.isAdmin || (this.isAdmin && this.isOwner)))
      ) {
        return true;
      } else {
        return false;
      }
    },
    mailInUse() {
      return this.user.errorEmail ? this.user.errorEmail : this.errorEmail;
    },
    isAdmin() {
      return this.user.isAdmin;
    },
    isOwner() {
      return this.user.email === this.getUserEmail;
    },
  },
  validations: {
    user: {
      name: {
        maxLength: maxLength(20),
      },
      email: {
        required,
        maxLength: maxLength(50),
        email,
      },
    },
  },
  methods: {
    ...mapActions({
      setRoles: "roles/setRoles",
      updateUser: "users/updateUser",
      createUser: "users/newUser",
    }),
    deleteUser() {
      this.onEdition = false;
      this.$emit("deleteUser");
    },
    cancelUser() {
      this.enabled = !this.enabled;
      this.onEdition = false;
      this.errorEmail = "";
      this.$emit("cancelUser");
    },
    onUpdateUser() {
      if (this.enabled || this.user.new) {
        this.user.surnames = "";
        if (this.user.new) {
          this.createUser(this.user)
            .then(() => {
              this.$emit("updateUser");
              this.user.new = false;
              this.onEdition = false;
              this.enabled = !this.enabled;
            })
            .catch(() => {
              this.errorEmail = this.$t("error.errorEmailEnUso");
            });
        } else {
          this.updateUser(this.user)
            .then(() => {
              this.onEdition = false;
              this.enabled = !this.enabled;
              this.$emit("updateUser");
            })
            .catch(() => {
              this.errorEmail = this.$t("error.errorEmailEnUso");
            });
        }
      } else {
        this.$emit("editingUser");
        this.onEdition = true;
        this.enabled = !this.enabled;
      }
    },

    checkEmailOnUse() {
      this.errorEmail = "";
    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 750));
    },
  },
  filters: {
    firstCapital(val) {
      return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
    },
  },
  components: {
    "vc-icon": Icon,
    vcBtnSimple: BtnSimple,
  },
  data() {
    return {
      visible: false,
      plusIcon,
      userIcon,
      enabled: false,
      onEdition: false,
      errorEmail: "",
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.visible = true;
    });
  },
};
</script>
