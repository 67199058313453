<template>
  <div class="w-full flex justify-end first:bg-orange-400">
    <div class="w-1/12 flex justify-center">
      <div class="w-1 h-full bg-orange-200"></div>
    </div>
    <div class="w-11/12">
      <p class="text-gray-600 font-semibold">{{data.question}}</p>
      <div class="mt-5 flex flex-wrap">
        <vc-question-button
          v-for="(answer, index) in answers"
          :key="index"
          :value="answer.value"
          :name="answer.label"
          :active="data.answer === answer.value"
          :available="answer.available"
          size="px-1 mt-2 w-1/2 md:px-2 md:w-1/3 md:px-4 lg:w-1/6 lg:mt-0 "
          @change="changeValue"
        ></vc-question-button>
      </div>
      <vc-comment v-model="data.notes"></vc-comment>
    </div>
  </div>
</template>

<script>
import QuestionButton from "@/components/questions/QuestionButton.vue";
import Comment from "@/components/questions/Comment.vue";
export default {
  data() {
    return {
      question: "",
      numberAnswers: 6
    };
  },

  props: {
    data: Object,
    answers: Array
  },
  components: {
    vcQuestionButton: QuestionButton,
    vcComment: Comment
  },

  methods: {
    changeValue: function(newValue) {
      this.data.answer = newValue;
    }
  }
};
</script>
