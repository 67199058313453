<template>
  <apexchart
    width="100%"
    height="120px"
    type="radialBar"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    categories: Array,
    series: Array,
  },
  data() {
    return {
      options: {
        chart: {
          height: 120,
          type: "radialBar",
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "diagonal1",
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [10, 150, 150],
            gradientToColors: ["#d15e15"],
          },
          colors: ["#F28C32"],
          opacity: 1,
        },
        plotOptions: {
          radialBar: {
            track: {
              background: "#F7B05E",
              strokeWidth: "100%",
            },
            hollow: {
              margin: 15,
              size: "50%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                color: "#d15e15",
                fontSize: "1.5rem",
                offsetY: 7,
                formatter(val) {
                  // return (val / 10).toFixed(2);
                  return val;
                },
              },
            },
          },
        },
        yaxis: {
          min: 0,
          max: 100,
        },

        stroke: {
          lineCap: "round",
        },
        labels: ["Progress"],
      },
    };
  },
};
</script>
