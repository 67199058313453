<template>
  <div v-bar class="w-full vb ml-20">
    <div>
      <div class="wrapper">
        <vc-header
          :display-name="$t('testDashboard.nuevoTest')"
          :icon-name="name"
          :email="getUserEmail"
          @changedLang="refreshReq"
        />
        <div v-if="!getSections || !getTests" class="text-center">
          <span class="text-orange-400 text-lg">{{
            $t("common.cargando")
          }}</span>
        </div>
        <div v-if="getSections && getTests" class="wrapper flex flex-col">
          <div v-if="creando" class="text-center">
            <span class="text-orange-400 text-lg">{{
              $t("common.creando")
            }}</span>
          </div>
          <div class="w-full md:w-2/3 lg:w-2/4 xl:w-1/3">
            <vc-box v-if="!creando" :icon="findIcon('test')">
              <div>
                <label for="nombreTest">{{ $t("testNew.nombreTest") }}:</label>
                <div class="px-2">
                  <input
                    v-model="testName"
                    type="text"
                    class="form-control w-full mt-2 mb-4 px-1 block border-b border-orange-400 text-sm focus:placeholder-orange-400 focus:border-orange-600 focus:outline-none"
                    :placeholder="$t('testNew.nombreTest')"
                  />
                </div>
              </div>
              <div v-if="!$v.testName.maxLength" class="error text-xs">
                {{
                  $t("error.errorCampoLargo", {
                    campo: $t("common.nombre"),
                    length: $v.testName.$params.maxLength.max,
                  })
                }}
              </div>
              <div>
                <label>{{ $t("testNew.asignarA") }}:</label>
                <div class="px-2 mb-4">
                  <SelectCustom
                    v-model="asignadoA"
                    :options="usersArray"
                    stylewrap="text-grey-500 border-b mt-2 border-orange-400 text-sm px-1 h-6 "
                    styleoption="px-1 text-sm  text-orange-400 text-left hover:text-white hover:bg-orange-600 h-6"
                    styleoptionwrap="bg-white border-orange-600 border border-t-0 rounded-b"
                    styleicon="border-orange-400"
                  />
                </div>
              </div>
              <div v-if="testsArray.length > 1">
                <label>{{ $t("testNew.copiarTest") }}:</label>
                <div class="px-2 mb-4">
                  <SelectCustom
                    v-model="testCopy"
                    :options="testsArray"
                    stylewrap="text-grey-500 border-b mt-2 border-orange-400 text-sm px-1 h-6 "
                    styleoption="px-1 text-sm  text-orange-400 text-left hover:text-white hover:bg-orange-600 h-6"
                    styleoptionwrap="bg-white border-orange-600 border border-t-0 rounded-b"
                    styleicon="border-orange-400"
                  />
                </div>
              </div>
              <div>
                <p>{{ $t("testNew.paquetePreguntas") }}:</p>

                <div class="px-3">
                  <div
                    v-for="(section, index) in getSections"
                    :key="index"
                    class="flex h-8 my-2"
                  >
                    <input
                      :id="`cbox${index}`"
                      v-model="selectedSections"
                      type="checkbox"
                      :value="section.id"
                      :disabled="!section.optional || testCopy"
                      @input="$v.selectedSections.$touch()"
                    />
                    <!-- ml-2 -->
                    <label
                      class="text-orange-400 flex justify-center items-center leading-none"
                      :for="`cbox${index}`"
                      >{{ section.name }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="flex justify-end">
                <button
                  class="btn-small focus:outline-none w-24"
                  :disabled="
                    (!testCopy && $v.testName.$invalid) ||
                    $v.testName.$invalid ||
                    $v.selectedSections.$invalid ||
                    !hasCreationRole
                  "
                  @click="saveTest"
                >
                  {{ saveText }}
                </button>

                <router-link
                  :to="{ name: 'testDashboard' }"
                  class="btn-smallInverted text-center w-24 mx-2 focus:outline-none"
                >
                  {{ $t("test.volver") }}
                </router-link>
              </div>
            </vc-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { maxLength, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

import { icons } from "@/assets/data/icons.js";
import Box from "@/components/Box.vue";
import Header from "@/components/Header.vue";
import SelectCustom from "@/components/SelectCustom.vue";

export default {
  components: {
    vcBox: Box,
    SelectCustom,
    vcHeader: Header,
  },
  data() {
    return {
      name: "tests",
      creando: false,
      loading: false,
      testName: "",
      asignadoA: "",
      testCopy: null,
      testsArray: [{ value: 0, name: "" }],
      usersArray: [],
      selectedSections: [],
      neededCreationRole: 3,
    };
  },
  created() {
    if (!this.getSections) {
      this.setSections();
    } else {
      this.setValues();
    }
    this.setUsers();
    this.setTests();
  },
  computed: {
    ...mapGetters({
      getSections: "tests/getSections",
      getTestSections: "tests/getTestSections",
      getUsers: "users/getTestsUsers",
      getUserRoles: "auth/getRoles",
      getTests: "tests/getTests",
      getUserEmail: "auth/userEmail",
    }),
    saveText() {
      return this.loading
        ? this.$t("common.cargando")
        : this.$t("common.guardar");
    },
    hasCreationRole() {
      if (this.getUserRoles) {
        return this.getUserRoles.includes(this.neededCreationRole);
      } else {
        return false;
      }
    },
  },
  validations: {
    testName: {
      required,
      maxLength: maxLength(100),
    },
    selectedSections: {
      required,
    },
  },
  watch: {
    getTests(newValue) {
      //obtenemos todos los test y los introducimos en el array del desplegable
      if (newValue) {
        this.getTests.forEach((element) => {
          this.testsArray.push({
            value: element.id,
            name: element.name,
          });
        });
      }
    },
    getUsers(newValue) {
      if (newValue) {
        let authUser = newValue.find((user) => {
          return user.email === this.getUserEmail;
        });
        if (!authUser) authUser = newValue[0];
        this.asignadoA = authUser.id;
        this.usersArray = newValue.map((user) => ({
          name: user.name,
          value: user.id,
        }));
      }
    },
    getSections(newValue) {
      if (newValue) {
        this.setValues();
      }
    },
    testCopy(newValue) {
      //si se selecciona un test se cargan sus secciones, en otro caso, se cargan las seciones no opcionales por defecto
      // this.loading = true;
      if (newValue) {
        this.selectedSections = [];
        if (newValue != null) {
          this.setTestSections(newValue);
        } else {
          this.setValues();
        }
      } else {
        this.testCopy = null;
      }
    },

    getTestSections(newValue) {
      //se cargan en las secciones seleccionadas, las secciones de un test solicitado
      if (newValue) {
        newValue.forEach((element) => {
          this.selectedSections.push(element.id);
        });
      }
    },
  },
  methods: {
    ...mapActions({
      setSections: "tests/setSections",
      setTests: "tests/setTests",
      setTestSections: "tests/setTestSections",
      setUsers: "users/setUsers",
      createTest: "tests/createTest",
      copyTest: "tests/copyTest",
    }),

    setValues() {
      // se meten dentro del array de secciones seleccionadas las que secciones no opcionales
      this.getSections.forEach((element) => {
        if (!element.optional) {
          this.selectedSections.push(element.id);
        }
      });
      // this.loading = false;
    },

    saveTest() {
      this.creando = true;
      const newTest = {
        name: this.testName,
        assignedTo: this.asignadoA,
      };

      if (this.testCopy) {
        newTest.testCopy = this.testCopy;
        this.copyTest(newTest).then((res) => {
          this.navigateTo({ id: res.id, section_id: res.current });
          this.creando = false;
        });
      } else {
        newTest.sections = this.selectedSections;
        this.createTest(newTest).then((res) => {
          this.navigateTo({ id: res.id, section_id: res.current });
          this.creando = false;
        });
      }
    },
    refreshReq() {
      this.setSections();
    },
    navigateTo(to) {
      this.$router
        .push({
          name: "test",
          params: to,
        })
        .catch(() => {});
    },
    findIcon(value) {
      return icons.find((icon) => icon.name == value);
    },
  },
};
</script>
