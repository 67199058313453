<template>
  <div v-bar class="w-full vb ml-20">
    <div>
      <div class="wrapper flex flex-col">
        <vc-header
          icon-name="tests"
          :display-name="'test'"
          :email="getUserEmail"
          @changedLang="refreshReq"
        />
        <div v-if="!loaded" class="text-center">
          <span class="text-orange-400 text-lg">{{
            $t("common.cargando")
          }}</span>
        </div>
        <div v-if="getTestSummary && loaded" class="flex flex-col">
          <div id="charDiv" class="bg-orange-200 p-4 pb-10 rounded mt-5">
            <div class="flex flex-row justify-between w-full">
              <div class="self-center">
                <span
                  v-if="getEnterprise"
                  class="text-xl text-orange-600 font-bold"
                >
                  {{ getEnterprise.name }}
                </span>
                <span
                  v-if="getEnterprise && getTest"
                  class="text-xl text-orange-600 font-bold"
                >
                  -
                </span>
                <span v-if="getTest" class="text-xl text-orange-600 font-bold">
                  {{ getTest.name }}
                </span>
              </div>
              <div class="self-center flex flex-col md:flex-row">
                <vc-btn-simple
                  v-if="hasEditionRole"
                  :name="$t('testDashboard.editarTest')"
                  style-wrap="btn-simple my-1"
                  :disabled="!hasEditionRole"
                  @click="editTest"
                />
                <vc-btn-simple
                  :name="textDownload"
                  style-wrap="btn-simple my-1"
                  disable-style="btn-simple bg-gray-500 border-gray-500 md:my-1"
                  :disabled="getTest && pdfLoading"
                  @click="downloadPdf(getTest.name)"
                />
              </div>
            </div>
            <div class="chart-area flex px-10 flex-col md:flex-row">
              <div class="w-full chart-circle overflow-hidden lg:w-1/2">
                <vc-circle-chart :series="calcTotalScore" />
                <div class="w-full flex-col-center items-center font-semibold">
                  <span class="text-orange-400 text-sm">{{
                    $t("informes.notaGlobal")
                  }}</span>
                  <span class="text-orange-600">{{
                    $t("informes.ciberseguridad")
                  }}</span>
                </div>
              </div>
              <div class="w-full chart-bars overflow-hidden lg:w-1/2">
                <vc-bar-chart
                  :key="Math.random()"
                  :series="series"
                  :categories="categories"
                  :tooltip="true"
                  :show-labels="true"
                  :show-legend="false"
                />
              </div>
            </div>
          </div>
          <div
            id="summaryDiv"
            class="advice-section bg-white p-10 rounded w-full lg:w-3/4 mx-auto mb-5 mt-5 lg:-mt-5 shadow-md border-t-4 border-orange-600"
          >
            <TabRemark :data="testSummary.remarks" />
            <TabAverage :data="testSummary.average" />
            <TabTasks :data="testSummary.tasks" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BtnSimple from "@/components/BtnSimple.vue";
import BarChart from "@/components/charts/BarChart.vue";
import CircleChart from "@/components/charts/CircleChart.vue";
import Header from "@/components/Header.vue";
import TabAverage from "@/components/tabs/TabAverage.vue";
import TabRemark from "@/components/tabs/TabRemark.vue";
import TabTasks from "@/components/tabs/TabTasks.vue";

import axios from "../axios-auth";

export default {
  name: "TestResult",
  components: {
    vcHeader: Header,
    vcBtnSimple: BtnSimple,
    vcBarChart: BarChart,
    vcCircleChart: CircleChart,
    TabRemark,
    TabAverage,
    TabTasks,
  },
  data() {
    return {
      loaded: false,
      pdfLoading: false,
      categories: [],
      neededEditionRole: 4,
      series: [
        {
          name: `${this.$t("informes.valor").charAt(0).toUpperCase()}${this.$t(
            "informes.valor"
          ).slice(1)}`,
          type: "column",
          data: [],
        },
      ],
    };
  },
  created() {
    this.setTestSummary({ idTest: this.$route.params.id }).then(() => {
      if (this.getTestSummary) {
        this.setBarValues();
      }
    });
    if (!this.getEnterprise) {
      this.setEnterprise();
    }
  },
  computed: {
    ...mapGetters({
      getUserEmail: "auth/userEmail",
      getTestSummary: "tests/getTestSummary",
      getUserRoles: "auth/getRoles",
      getEnterprise: "enterprise/getEnterprise",
      getTest: "tests/getTest",
    }),
    calcTotalScore() {
      return [Math.round(this.getTestSummary.score)];
    },
    hasEditionRole() {
      if (this.getUserRoles.includes) {
        return this.getUserRoles.includes(this.neededEditionRole);
      } else {
        return false;
      }
    },
    testSummary() {
      return this.getTestSummary;
    },
    textDownload() {
      if (!this.getTest) {
        return "cargando informaciones...";
      }
      return this.pdfLoading
        ? this.$t("informes.generandoInforme")
        : this.$t("informes.descargarInforme");
    },
  },
  methods: {
    ...mapActions({
      setTestSummary: "tests/setTestSummary",
      setTest: "tests/setTest",
      setEnterprise: "enterprise/setEnterprise",
    }),
    editTest() {
      const firstSection = this.getTestSummary.sections.sections[0].id;

      // let firstSection = this.getTestSummary.current;
      const idTest = this.$route.params.id;

      const route = {
        name: "test",
        params: { id: idTest, section_id: firstSection },
      };

      this.$router.push(route);
    },
    deleteTest() {},
    async downloadPdf(testName) {
      this.pdfLoading = true;
      axios
        .get(`/tests/pdf/${this.$route.params.id}`, {
          responseType: "blob",
        })
        .then((r) => r.data)
        .then((data) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //create download
          const downloadLink = document.createElement("a");
          const fileName = `Informe Avalua-T - Test ${testName}.pdf`;

          downloadLink.href = fileURL;
          downloadLink.download = fileName;
          downloadLink.click();
          this.pdfLoading = false;
        });
    },
    refreshReq() {
      this.loaded = false;
      this.setTestSummary({ idTest: this.$route.params.id });
    },
    setBarValues() {
      this.categories = [];
      this.series[0].data = [];
      //Asi se añaden columnas para que solo aparezca una
      this.getTestSummary.sections.sections.forEach((element) => {
        this.categories.push(element.name);
        this.series[0].data.push(Math.round(element.score));
      });
    },
  },
  watch: {
    getTestSummary(newValue) {
      if (newValue) {
        this.loaded = true;
        this.setBarValues();
      }
    },
  },
};
</script>
