<template>
  <!-- //Remarks -->
  <div id="adviceRemark" class="advice-point" v-if="data.questions">
    <div class="my-3">
      <h3 class="text-xl text-orange-400">{{questionsTitle | cleanHtml}}</h3>
      <div v-if="data.sections && data.sections.length > 0">
        <div v-for="(section, index) in data.sections" :key="index" class="px-4 my-4">
          <span
            class="text-l font-semibold"
            v-if="questionsFiltered(section.id).length > 0"
          >{{section.name}}</span>

          <ol>
            <li
              v-for="(question, index) in questionsFiltered(section.id)"
              :key="index"
              class="pt-2"
            >- {{question.question}}</li>
          </ol>
        </div>
      </div>

      <div v-else class="p-4">
        <ol>
          <li
            v-for="(question, index) in data.questions.questions"
            :key="index"
            class="pt-2"
          >- {{question.question}}</li>
        </ol>
      </div>
      <p class="mt-5" v-if="note">{{note | cleanHtml}}</p>
    </div>
  </div>
</template>

<script>
// import ProgressBar from "@/components/ProgressBar.vue";
export default {
  name: "AdviceRemark",
  props: {
    data: Object,
    note: String
  },
  methods: {
    questionsFiltered(idSection) {
      return this.data.questions.questions.filter(
        question => question.idsection == idSection
      );
    }
  },
  computed: {
    questionsTitle() {
      return this.data.questions.content;
    }
  },
  filters: {
    cleanHtml: function(value) {
      if (!value) return "";
      value = value.toString();
      let first = value.indexOf(">") + 1;
      let next = value.indexOf("</");
      return value.substring(first, next);
    }
  }
};
</script>