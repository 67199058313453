<template>
  <div class="flex p-2 justify-between items-center">
    <div>
      <button
        class="btn-link"
        :disabled="isFirstSection"
        @click="$emit('prevSection')"
      >
        <div class="w-2 h-2 text-orange-600 fill-current mr-2 flip-x">
          <vc-Icon :icon="arrowIcon" />
        </div>
        {{ $t("test.volver") }}
      </button>
    </div>
    <div>
      <button
        v-if="!isLastSection"
        class="btn-link"
        :disabled="isLastSection || disabled"
        @click="$emit('nextSection')"
      >
        {{ $t("test.siguiente") }}
        <div class="w-2 h-2 text-orange-600 fill-current ml-2">
          <vc-Icon :icon="arrowIcon" />
        </div>
      </button>

      <button
        v-if="isLastSection"
        class="btn-link"
        :disabled="disabled"
        @click="$emit('finishTest')"
      >
        {{ $t("test.finalizarTest") }}
        <div class="w-2 h-2 text-orange-600 fill-current ml-2">
          <vc-Icon :icon="arrowIcon" />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { arrowIcon } from "@/assets/data/icons.js";
import Icon from "@/components/icon/Icon.vue";

export default {
  name: "NavigationQuestion",
  components: {
    vcIcon: Icon,
  },
  props: {
    isLastSection: {
      type: Boolean,
      default: false,
    },
    isFirstSection: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrowIcon,
    };
  },
};
</script>

<style lang="scss" scoped></style>
