<template>
  <div class="py-2 px-4 my-4 bg-orange-100 text-orange-600 rounded-lg flex">
    <span>{{name}}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    }
  }
};
</script>