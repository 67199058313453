<template>
  <div class="mb-4">
    <label :for="formField.nombre" class="clearfix">{{formField.displayName}}</label>
    <input
      :type="formField.type"
      :class="styleWrap"
      :id="formField.id"
      :placeholder="formField.placeholder"
      v-model="inputValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    formField: Object,
    styleWrap: {
      type: String,
      default:
        "form-control h-8 w-full md:w-2/3 mt-2 py-2 px-4 border border-black rounded text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none",
      required: false
    }
  },
  data() {
    return {
      inputValue: this.value
    };
  },
  watch: {
    inputValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>