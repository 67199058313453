<template>
  <!-- //Average -->
  <div v-if="data.sections_average" id="adviceAverage" class="advice-point">
    <div
      v-for="(section, index) in data.sections"
      :key="section.id"
      class="w-full my-3"
    >
      <span class="text-l font-semibold">{{ section.name }}</span>
      <div class="w-full">
        <div class="flex">
          <div class="w-20 flex-col-center">
            <div class="h-11 mx-auto flex-col-center text-center">
              <span class="text-l font-semibold text-orange-600">{{
                $t("common.nota")
              }}</span>
            </div>
          </div>
          <div class="w-full flex-col-center">
            <ProgressBar :percentage="Math.round(section.score)" />
          </div>
        </div>
        <div class="flex">
          <div class="w-20 flex-col-center">
            <div class="h-11 mx-auto flex-col-center text-center">
              <span class="text-l font-semibold">{{ $t("common.media") }}</span>
            </div>
          </div>
          <div class="w-full flex-col-center">
            <ProgressBar
              :percentage="Math.round(data.sections_average[index].score)"
              :secundary-color="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "AdviceAverage",
  filters: {
    cleanHtml(value) {
      if (!value) return "";
      value = value.toString();
      const first = value.indexOf(">") + 1;
      const next = value.indexOf("</");
      return value.substring(first, next);
    },
    formatoLista(value) {
      if (!value) return "";
      return "- ", value;
    },
  },
  components: {
    ProgressBar,
  },
  props: {
    data: Object,
  },
  computed: {
    title() {
      return this.data.content;
    },
    averageTitle() {
      return this.data.average.title;
    },
  },
};
</script>
