import axios from "../../axios-auth";

export default {
  namespaced: true,
  state: {
    enterprise: null,
    types: null,
    provinces: null,
  },
  getters: {
    getEnterprise: (state) => {
      return state.enterprise;
    },
    getTypes: (state) => {
      return state.types;
    },
    getProvinces: (state) => {
      return state.provinces;
    },
  },
  actions: {
    setEnterprise: ({ commit }) => {
      new Promise((resolve, reject) => {
        axios
          .get("/enterprise")
          .then((r) => r.data)
          .then((enterprise) => {
            commit("setEnterprise", enterprise);
            resolve();
          })
          .catch((e) => reject(e));
      });
    },
    setEnterpriseBasic: ({ commit }) => {
      new Promise((resolve, reject) => {
        axios
          .get("/enterprise")
          .then((r) => r.data)
          .then((enterprise) => {
            commit("setEnterprise", enterprise);
            resolve();
          })
          .catch((e) => reject(e));
      });
    },
    setEnterpriseAsync: ({ commit }) => {
      new Promise((resolve, reject) => {
        axios
          .get("/enterprise")
          .then((r) => r.data)
          .then((enterprise) => {
            commit("setEnterprise", enterprise);
            resolve();
          })
          .catch((e) => reject(e));
      });
    },
    setTypes: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit("setTypes", null);
        axios
          .get("/enterprise/types")
          .then((types) => {
            commit("setTypes", types.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setProvinces: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios
          .get("/enterprise/provinces")
          .then((provinces) => {
            commit("setProvinces", provinces.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateEnterprise: ({ dispatch }, enterprise) => {
      enterprise.province = enterprise.idprovince;
      axios
        .put("/enterprise/", enterprise)
        .then((r) => r.data)
        .then(() => {
          dispatch("setEnterprise");
        });
    },
  },
  mutations: {
    setEnterprise: (state, enterprise) => {
      state.enterprise = enterprise;
    },
    setTypes: (state, types) => {
      state.types = types;
    },
    setProvinces: (state, provincesList) => {
      state.provinces = provincesList;
    },
  },
};
