<template>
  <div class="w-full bg-csirt">
    <div class="container mx-auto h-full flex justify-center items-center">
      <div class="w-3/5 flex relative">
        <div class="form-background-rotate" />
        <div class="bg-white shadow-md z-20 px-10 py-5 m-auto w-3/4 h-56">
          <h2 class="text-orange-600 text-3xl font-bold leading-normal">
            {{ titulo }}
          </h2>
          <div v-if="!contrasenyaEnviada">
            <vc-input
              v-model="emailValue"
              :form-field="email"
              style-wrap="form-control h-8 w-full w-full mt-2 py-2 px-4 border border-black rounded text-sm focus:placeholder-orange-400 focus:border-orange-400 focus:outline-none"
              @input="$v.emailValue.$touch()"
            />
            <div v-if="$v.emailValue.$error" class="error text-xs">
              {{ $t("error.errorEmail") }}
            </div>
            <div class="flex flex-wrap py-2">
              <button
                class="btn-square w-1/5 rounded-sm"
                :disabled="
                  loading ||
                    $v.emailValue.$model == '' ||
                    $v.emailValue.$invalid
                "
                @click.prevent="onChangePassword"
              >
                {{ loadingText }}
              </button>
            </div>
          </div>
          <div v-else class="mt-5">
            <p v-html="peticionMsg" />
            <br />
            <p>{{ $t("common.redirect") }}.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import { arrowIcon, marcaCsirtIcon } from "@/assets/data/icons.js";
import StartInput from "@/components/StartInput.vue";

export default {
  data() {
    return {
      loading: false,
      peticionMsg:
        `${this.$t('password.correcto')}.<br/><em>${this.$t("password.spam")}.</em>`,
      tituloForgot: this.$t("password.enviado"),
      arrowIcon,
      marcaCsirtIcon,
      email: {
        name: "email",
        type: "email",
        displayName: "Email:",
        placeholder: "Email",
        id: "email",
      },
      emailValue: "",
      contrasenyaEnviada: false,
    };
  },
  computed: {
    loadingText() {
      return this.loading ? `${this.$t("password.cargando")}...` : "Enviar";
    },
    titulo() {
      return this.contrasenyaEnviada
        ? this.tituloForgot
        : this.$t("password.recuperar");
    },
  },
  validations: {
    emailValue: {
      email,
    },
  },
  methods: {
    ...mapActions({
      forgotPassword: "auth/forgotPassword",
    }),
    async onChangePassword() {
      const payload = {
        email: this.emailValue,
      };
      try {
        await this.forgotPassword(payload);
        setTimeout(() => this.$router.push({ name: "login" }), 10000);
      } catch (e) {
        this.peticionMsg = `${this.$t("password.problema")}.`;
        this.tituloForgot = "Error";
        setTimeout(() => this.$router.push({ name: "login" }), 10000);
      }
      this.contrasenyaEnviada = true;
    },
  },
  components: {
    vcInput: StartInput,
  },
};
</script>

<style></style>
