<template>
  <div class="w-full vb" v-bar>
    <div>
      <div class="wrapper flex-col-center items-center">
        <div class="bg-white p-14 shadow-lg rounded flex justify-center items-center flex-col">
          <div class="border-solid border-b-2 border-orange-400 py-4">
            <h2 class="text-4xl text-gray-500 font-bold">Página no encontrada</h2>
          </div>

          <div class="mt-10">
            <button @click="goHome" class="btn-simple">Ir a mi dashboard</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: "testDashboard" });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>