<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="icon.viewBox" class="h-full" :class="{'mx-auto': center}">
    <component
      v-for="(element, index) in icon.elements"
      :is="`vc-${element.type}`"
      :key="index"
      :data="element.data"
    />
  </svg>
</template>

<script>
import Circle from "./Circle.vue";
import Path from "./Path.vue";
export default {
  props: {
    icon: Object,
    center: {
      default: true,
      type: Boolean

    }
  },
  components: {
    "vc-circle": Circle,
    "vc-path": Path
  }
};
</script>