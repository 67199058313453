<template>
  <div class="mt-10 comment" :class="{ 'show-comment': toggled }">
    <div class="flag cursor-pointer justify-start items-start px-4">
      <div class="flex flex-col flex-grow" @click="toggle">
        <div
          class="text-xs text-gray-500 self-end border-b border-gray-30 w-full flex justify-end"
        >
          Leyenda:
          <div
            class="ml-2 mt-1 w-2 h-2 rotate text-orange-600 fill-current -rotate-90 transition-duration-250 arrow"
          >
            <vc-icon :icon="arrowIcon"></vc-icon>
          </div>
        </div>
        <div class="overflow-hidden h-0 box-comment transition-duration-250">
          <div
            class="bg-gray-200 w-full placeholder-gray-600 resize-none px-5 py-2 border border-gray-600 rounded focus:outline-none flex flex-col"
          >
            <div>
              <span class="font-semibold">{{ $t("leyenda.leyenda") }}</span>
            </div>
            <div class="w-full px-4">
              <div class="flex flex-wrap">
                <div
                  class="w-full md:w-1/3 flex justify-left lg:justify-center"
                >
                  <span class="font-semibold inline-block md:w-15 text-right"
                    >NS/NC:&nbsp;</span
                  >
                  <span class="inline-block md:flex-grow">{{
                    $t("leyenda.NSNC")
                  }}</span>
                </div>
                <div
                  class="w-full md:w-1/3 flex justify-left lg:justify-center"
                >
                  <span class="font-semibold inline-block md:w-15 text-right"
                    >1:&nbsp;</span
                  >
                  <span class="inline-block md:flex-grow">{{
                    $t("leyenda.nada")
                  }}</span>
                </div>
                <div
                  class="w-full md:w-1/3 flex justify-left lg:justify-center"
                >
                  <span class="font-semibold inline-block md:w-15 text-right"
                    >2:&nbsp;</span
                  >
                  <span class="inline-block md:flex-grow">{{
                    $t("leyenda.poco")
                  }}</span>
                </div>
                <div
                  class="w-full md:w-1/3 flex justify-left lg:justify-center"
                >
                  <span class="font-semibold inline-block md:w-15 text-right"
                    >3:&nbsp;</span
                  >
                  <span class="inline-block md:flex-grow">{{
                    $t("leyenda.medio")
                  }}</span>
                </div>
                <div
                  class="w-full md:w-1/3 flex justify-left lg:justify-center"
                >
                  <span class="font-semibold inline-block md:w-15 text-right"
                    >4:&nbsp;</span
                  >
                  <span class="inline-block md:flex-grow">{{
                    $t("leyenda.mucho")
                  }}</span>
                </div>
                <div
                  class="w-full md:w-1/3 flex justify-left lg:justify-center"
                >
                  <span class="font-semibold inline-block md:w-15 text-right"
                    >5:&nbsp;</span
                  >
                  <span class="inline-block md:flex-grow">{{
                    $t("leyenda.todo")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon.vue";
import { arrowIcon } from "@/assets/data/icons.js";
export default {
  data() {
    return {
      toggled: true,
      arrowIcon,
      inputValue: this.value,
    };
  },
  props: {
    value: String,
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("input", value);
    },
  },
  components: {
    vcIcon: Icon,
  },
};
</script>
