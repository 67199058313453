<template>
  <div id="TestRemark">
    <div v-for="(remark, index) in data" :key="index">
      <div
        v-if="remark.questions && remark.questions.questions.length > 0"
        class="tab w-full border-b border-orange-600 overflow-hidden"
      >
        <div
          class="flex justify-between items-center cursor-pointer text-orange-600 hover:text-orange-400"
          @click="onToggle(remark)"
        >
          <span class="text-2xl font-semibold">{{ remark.title }}</span>
          <div
            class="ml-2 w-3 h-3 rotate fill-current transition-duration-100 arrow justify-end"
            :class="{
              'rotate-90': remark.toggle,
              '-rotate-90': !remark.toggle,
            }"
          >
            <vc-icon :icon="arrowIcon" />
          </div>
        </div>
        <div
          class="tab-content overflow-hidden px-2"
          :class="{ 'max-h-0': !remark.toggle }"
        >
          <AdviceRemark :data="remark" :note="remark.note" />
          <!-- <div v-if="data.subsections">
        <vc-subsection v-for="(advice,index2) in data.subsections" :key="index2" :data="advice"></vc-subsection>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { arrowIcon } from "@/assets/data/icons.js";
import Icon from "@/components/icon/Icon.vue";
import AdviceRemark from "@/components/tabs/AdviceRemark.vue";

export default {
  components: {
    vcIcon: Icon,
    AdviceRemark,
  },
  props: {
    title: String,
    data: Array,
  },
  data() {
    return {
      name,
      arrowIcon,
    };
  },
  methods: {
    onToggle(remark) {
      Vue.set(remark, "toggle", !remark.toggle);
    },
  },
};
</script>
