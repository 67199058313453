<template>
  <div v-bar class="w-full vb ml-20">
    <div>
      <div class="wrapper">
        <vc-header
          :icon-name="name"
          :display-name="$t('informes.informes')"
          :email="getUserEmail"
          @changedLang="refreshReq"
        />
        <div v-if="!loaded" class="text-center">
          <span class="text-orange-400 text-lg">{{
            $t("common.cargando")
          }}</span>
        </div>
        <div
          v-if="!lastTest && loaded"
          class="flex text-center justify-center items-center flex-col"
        >
          <h2 class="text-gray-500 text-2xl font-bold">
            {{ $t("informes.noTestCompletados") }}
          </h2>
          <span class="text-gray-600">{{
            $t("informes.noTestCompletadosText")
          }}</span>
        </div>
        <!-- v-if="getTests && getTestSummary && loaded" -->
        <div v-if="lastTest && loaded">
          <vc-section-separator :name="$t('informes.comparativa')" />
          <div class="flex w-full">
            <div class="w-2/4 p-4 pr-8 border-r border-orange-400">
              <div>
                <span class="text-orange-600 font-bold">Test {{ $t("informes.testSeleccionado") }}:</span>
              </div>
              <div class="test flex flex-wrap">
                <QuestionButton
                  v-for="(test, index) in completedTest"
                  :key="index"
                  :class="loadedData"
                  size="sm:px-4 w-full  sm:w-1/2 lg:w-1/3 my-4"
                  :name="test.name"
                  :value="test.id"
                  :available="test.id != selectedTestCompare && loadedChart"
                  :active="selectedTest === test.id"
                  @change="changeValue"
                />
              </div>
            </div>
            <div class="w-2/4 p-4 pl-8">
              <div>
                <span class="text-orange-600 font-bold">Test {{ $t("informes.testComparativo") }}:</span>
              </div>
              <div class="test flex flex-wrap">
                <QuestionButton
                  v-for="(test, index) in completedTest"
                  :key="index"
                  size="sm:px-4 w-full  sm:w-1/2 lg:w-1/3 my-4"
                  :class="loadedData"
                  :name="test.name"
                  :value="test.id"
                  :available="test.id != selectedTest && loadedChart"
                  :active="selectedTestCompare === test.id"
                  @change="changeValueCompare"
                />
                <QuestionButton
                  size="sm:px-4 w-full  sm:w-1/2 lg:w-1/3 my-4"
                  :class="loadedData"
                  :name="$t('common.seccion')"
                  :value="-1"
                  :available="loadedChart"
                  :active="selectedTestCompare === -1"
                  @change="changeValueCompare"
                />
              </div>
            </div>
          </div>
          <div class="bg-white p-4 mt-5 rounded transition-duration-250">
            <div
              v-if="!loadedChart"
              class="w-full text-center text-orange-600 p-4 transition-duration-250"
            >
              {{ $t("common.cargando") }}
            </div>
            <div v-else class="w-full flex flex-col lg:flex-row">
              <div class="w-full chart-circle overflow-hidden lg:w-1/3">
                <vc-circle-chart :key="randomkey" :series="calcTotalScore" />
                <div class="w-full flex-col-center items-center font-semibold">
                  <span class="text-orange-400 text-sm">{{
                    $t("informes.notaGlobal")
                  }}</span>
                  <span class="text-orange-600">{{
                    $t("informes.ciberseguridad")
                  }}</span>
                </div>
              </div>
              <div class="w-full chart-bars overflow-hidden lg:w-2/3">
                <vc-bar-chart
                  :key="randomkey"
                  :series="series"
                  :categories="getCategories"
                  :tooltip="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import axios from "@/axios-auth.js";
import BarChart from "@/components/charts/BarChart.vue";
import CircleChart from "@/components/charts/CircleChart.vue";
import Header from "@/components/Header.vue";
import QuestionButton from "@/components/questions/QuestionButton.vue";
import SectionSeparator from "@/components/SectionSeparator.vue";

export default {
  components: {
    vcHeader: Header,
    vcBarChart: BarChart,
    vcCircleChart: CircleChart,
    vcSectionSeparator: SectionSeparator,
    QuestionButton,
  },
  data() {
    return {
      loaded: false,
      loadedChart: false,
      name: this.$t("informes.informes"),
      lastTest: null,
      selectedTest: 0,
      selectedTestCompare: -1,
      categories: [],
      randomkey: 0,
      series: [
        {
          name: `${this.$t("informes.testSeleccionado")
            .charAt(0)
            .toUpperCase()}${this.$t("informes.testSeleccionado").slice(1)}`,
          type: "column",
          data: [],
        },
        {
          name: `${this.$t("informes.testComparativo")
            .charAt(0)
            .toUpperCase()}${this.$t("informes.testComparativo").slice(1)}`,
          type: "column",
          data: [],
        },
      ],
    };
  },
  created() {
    // if (!this.getEnterprise) {
    this.setTests().then(() => this.setEnterprise());
    // } else {
    // this.setTests();
    // }
  },
  computed: {
    ...mapGetters({
      getUserEmail: "auth/userEmail",
      getTests: "tests/getTests",
      getTestSummary: "tests/getTestSummary",
      getEnterprise: "enterprise/getEnterprise",
    }),
    calcTotalScore() {
      return Array.isArray(this.getTestSummary)
        ? [Math.round(this.getTestSummary[0].score)]
        : [Math.round(this.getTestSummary.score)];
    },
    getCategories() {
      return this.categories;
    },
    completedTest() {
      return this.getTests.filter((element) => element.completed);
    },
    loadedData() {
      return this.loadedChart ? "" : "pointer-events-none";
    },
  },
  methods: {
    ...mapActions({
      setTests: "tests/setTests",
      setTestSummary: "tests/setTestSummaryBasic",
      setEnterprise: "enterprise/setEnterpriseBasic",
    }),
    changeValue(newVal) {
      this.selectedTest = newVal;
      this.loadedChart = false;
      if (this.selectedTestCompare != -1) {
        this.setTestSummary({
          idTest: newVal,
          idTestCompare: this.selectedTestCompare,
        });
      } else {
        this.setTestSummary({
          idTest: newVal,
        });
      }
    },
    changeValueCompare(newVal) {
      this.selectedTestCompare = newVal;
      this.loadedChart = false;
      if (this.selectedTestCompare != -1) {
        this.setTestSummary({
          idTest: this.selectedTest,
          idTestCompare: newVal,
        });
      } else {
        this.setTestSummary({
          idTest: this.selectedTest,
        });
      }
    },
    async averageBySection() {
      const { data } = await axios.post(`tests/summary/${this.selectedTest}`, {
        idsector: this.getEnterprise.idType,
      });
      return data;
    },
    // valueToPercent(value) {
    //   return (value * 100) / 10;
    // },
    findLastTestCompleted() {
      const testsCompleted = this.getTests.filter(
        (element) => element.completed
      );
      return testsCompleted.pop();
    },
    refreshReq() {
      this.loadedChart = false;
      this.series = [
        {
          name: `${this.$t("informes.testSeleccionado")
            .charAt(0)
            .toUpperCase()}${this.$t("informes.testSeleccionado").slice(1)}`,
          type: "column",
          data: this.series[0].data,
        },
        {
          name: `${this.$t("informes.testComparativo")
            .charAt(0)
            .toUpperCase()}${this.$t("informes.testComparativo").slice(1)}`,
          type: "column",
          data: this.series[1].data,
        },
      ];
      if (this.selectedTestCompare != -1) {
        this.setTestSummary({
          idTest: this.selectedTest,
          idTestCompare: this.selectedTestCompare,
        });
      } else {
        this.setTestSummary({
          idTest: this.selectedTest,
        });
      }
    },
    setLastTest() {
      this.lastTest = this.findLastTestCompleted();
      if (this.lastTest) {
        this.selectedTest = this.lastTest.id;
        this.loaded = true;
        this.setTestSummary({ idTest: this.lastTest.id });
      } else {
        //no hay test completados
        this.loaded = true;
      }
    },
    async setBarValues() {
      // limpia los arrays antes de setearlos
      this.categories = [];
      this.series[0].data = [];
      this.series[1].data = [];

      // si es un test comparado con otro test
      if (Array.isArray(this.getTestSummary)) {
        this.compareTestSections(
          this.getTestSummary[0].sections.sections,
          this.getTestSummary[1].sections.sections
        );
      } else {
        // compara un test con la media de sus sector
        const averageSections = await this.averageBySection();
        this.compareTestSections(
          this.getTestSummary.sections.sections,
          averageSections
        );
      }
      this.loadedChart = true;
    },
    /* compara las secciones de dos test y rellena los arrays que se pasan a las graficas */
    compareTestSections(sectionsTest1, sectionsTest2) {
      sectionsTest1.forEach((element1, index) => {
        this.categories.push(element1.name);
        this.series[0].data.push(Math.round(element1.score));
        this.series[1].data.push(0); // pone tantos ceros en la media como secciones tiene
        sectionsTest2.forEach((element2) => {
          if (element1.id === element2.id) {
            this.series[1].data[index] = element2.score; // sustituye los valores de la media en el que sus secciones son iguales
          }
        });
      });
    },
  },
  watch: {
    getTests(newValue) {
      if (newValue) {
        this.setLastTest();
      }
    },
    getTestSummary(newValue) {
      if (newValue) {
        this.setBarValues();
        this.randomkey = Math.random();
      }
    },
  },
};
</script>

<style scoped></style>
