<template>
  <div v-bar class="w-full vb ml-20">
    <div>
      <div class="wrapper">
        <vc-header
          :icon-name="name"
          :display-name="$t('configuracion.configuracion')"
          :email="getUserEmail"
          @changedLang="refreshReq"
        />
        <!-- {{getUserSession}} -->
        <vc-section-separator :name="$t('configuracion.datosEmpresa')" />
        <div class="w-full xl:w-2/3">
          <vc-box
            v-if="getEnterprise"
            :enable="enabledEnterprise"
            :icon="enterpriseIcon"
          >
            <div class="flex">
              <div id="nombreSectorWrapper" class="w-2/3 pl-5">
                <div class="flex">
                  <div class="flex-grow pr-4">
                    <div class="mb-4">
                      <label for="nombre" class="clearfix">{{ $t("common.nombre") | firstCapital }}:</label>
                      <div class="pl-4">
                        <input
                          v-model="enterprise.name"
                          class="form-control w-full block mt-2 px-1 border-b text-sm placeholder-orange-300 focus:placeholder-orange-500 border-orange-400 focus:border-orange-600 focus:outline-none"
                          :placeholder="$t('common.nombre') | firstCapital"
                          @input="$v.enterprise.name.$touch()"
                        />
                      </div>
                    </div>
                    <div
                      v-if="!$v.enterprise.name.maxLength"
                      class="error text-xs"
                    >
                      {{
                        $t("error.errorCampoLargo", {
                          campo: $t("common.nombre"),
                          length: $v.enterprise.name.$params.maxLength.max,
                        })
                      }}
                    </div>
                  </div>
                  <div class="w-24">
                    <div class="mb-4">
                      <label for="CIF">CIF:</label>
                      <div class="pl-4">
                        <input
                          v-model="enterprise.cif"
                          class="form-control w-full block mt-2 px-1 border-b text-sm placeholder-orange-300 focus:placeholder-orange-500 border-orange-400 focus:border-orange-600 focus:outline-none"
                          placeholder="CIF"
                          @input="$v.enterprise.cif.$touch()"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        !$v.enterprise.cif.maxLength ||
                          !$v.enterprise.cif.minLength
                      "
                      class="error text-xs"
                    >
                      {{ $t("error.errorCIF") }}
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <label for="sector">{{ $t("common.sector") }}:</label>

                  <div v-if="listaTipos.length" class="pl-4">
                    <SelectCustom
                      v-model="enterprise.idType"
                      :options="listaTipos"
                      :stylewrap="styleSelectCustom"
                      styleoption="p-1 text-sm  text-orange-400 text-left hover:text-white hover:bg-orange-600"
                      styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b"
                      styleicon="border-orange-400"
                      :disabled="!enabledEnterprise"
                    />
                  </div>
                </div>
              </div>
              <div id="ciudadProvinciaWrapper" class="w-1/3 pl-5">
                <div class="mb-4">
                  <label for="province" class="clearfix min-h-5">{{ $t("common.provincia") }}:</label>
                  <div v-if="listaProvincias.length" class="pl-4">
                    <SelectCustom
                      v-model="enterprise.idprovince"
                      :options="listaProvincias"
                      :stylewrap="styleSelectCustom"
                      styleoption="p-1 text-sm  text-orange-400 text-left hover:text-white hover:bg-orange-600"
                      styleoptionwrap="bg-white border-orange-400 border border-t-0 rounded-b "
                      styleicon="border-orange-400"
                      :disabled="!enabledEnterprise"
                    />
                  </div>
                </div>

                <div class="mb-4">
                  <label for="municipio">{{ $t("common.municipio") | firstCapital }}:</label>
                  <div class="pl-4">
                    <input
                      v-model="enterprise.town"
                      class="form-control w-full mt-2 px-1 block border-b text-sm placeholder-orange-300 focus:placeholder-orange-500 border-orange-400 focus:border-orange-600 focus:outline-none min-h-5"
                      :placeholder="$t('common.municipio') | firstCapital"
                      @input="$v.enterprise.town.$touch()"
                    />
                  </div>
                </div>
                <div v-if="!$v.enterprise.town.maxLength" class="error text-xs">
                  {{
                    $t("error.errorCampoLargo", {
                      campo: $t("common.municipio"),
                      length: $v.enterprise.town.$params.maxLength.max,
                    })
                  }}
                </div>
              </div>
            </div>
            <div class="flex w-full justify-end mt-6">
              <div
                v-if="hasEnterpriseRole"
                class="overflow-hidden transition-duration-500 whitespace-nowrap"
                :class="divExpanded"
              >
                <vc-btn-simple
                  :name="editarGuardar"
                  style-wrap="text-white bg-orange-600 py-1 uppercase text-xs font-semibold rounded whitespace-nowrap w-20 border-2 border-orange-600"
                  disable-style="text-white bg bg-gray-500 py-1 uppercase text-xs font-semibold rounded whitespace-nowrap w-20  border-2 border-gray-500"
                  :disabled="
                    $v.enterprise.town.$invalid ||
                      $v.enterprise.cif.$invalid ||
                      $v.enterprise.name.$invalid
                  "
                  @click="onSaveEnterprise"
                />

                <vc-btn-simple
                  :name="$t('common.cancelar')"
                  style-wrap="text-orange-600 bg-white uppercase text-xs font-semibold rounded whitespace-nowrap border-orange-600 ml-2 w-20 py-1 border-2"
                  @click="onCancelEnterprise"
                />
              </div>
            </div>
          </vc-box>
        </div>
        <Modal
          v-if="showModal"
          :titulo="$t('configuracion.eliminarUsuario')"
          :descripcion="$t('configuracion.descripcionEliminarUsuario')"
          @close="showModal = false"
          @confirm="
            deleteSelectedUser();
            showModal = false;
          "
        />
        <vc-section-separator :name="$t('configuracion.usuarios')">
          <div v-if="hasUserRole" class="w-5 h-5 mx-2 self-center">
            <button
              v-tooltip="$t('configuracion.nuevoUsuario')"
              class="focus:outline-none w-full h-full"
              :disabled="editingUser"
              @click="newUser"
            >
              <span :class="styleIconAddUser">
                <vc-icon :icon="plusIcon" />
              </span>
            </button>
          </div>
        </vc-section-separator>
        <transition-group
          v-if="users"
          tag="div"
          name="fade"
          class="w-full flex flex-wrap"
        >
          <div
            v-for="user in users.slice().reverse()"
            :key="user.id"
            class="w-full sm:w-full md:w-1/2 xl:w-1/3"
          >
            <vcBoxUser
              :user="user"
              :show-buttons="hasUserRole"
              :editable="editable"
              @deleteUser="onDeleteUser(user)"
              @updateUser="onSaveUser(user)"
              @editingUser="onEditingUser()"
              @cancelUser="onCancelUser(user)"
              @inputEmail="checkEmailOnUse(user)"
            />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  alphaNum,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

import {
  enterpriseIcon,
  newUserIcon,
  plusIcon,
  userIcon,
} from "@/assets/data/icons.js";
import Box from "@/components/Box.vue";
import BoxUser from "@/components/BoxUser.vue";
import BtnSimple from "@/components/BtnSimple.vue";
import Header from "@/components/Header.vue";
import Icon from "@/components/icon/Icon.vue";
import Modal from "@/components/Modal.vue";
import SectionSeparator from "@/components/SectionSeparator.vue";
import SelectCustom from "@/components/SelectCustom.vue";

export default {
  name: "Configuracion",
  created() {
    this.setUsers().then(() => this.setUserValues());
    this.setEnterprise().then(() => this.setEnterpriseValues());

    if (!this.getRoles) {
      this.setRoles();
    }
    if (!this.getTypes) {
      this.setTypes().then(() => {
        this.setTypesValues();
      });
    } else {
      this.setTypesValues();
    }

    if (!this.getProvinces) {
      this.setProvinces().then(() => {
        this.setProvincesValues();
      });
    } else {
      this.setProvincesValues();
    }
  },
  methods: {
    ...mapActions({
      setEnterprise: "enterprise/setEnterprise",
      deleteUser: "users/deleteUser",
      updateEnterprise: "enterprise/updateEnterprise",
      setUsers: "users/setUsers",
      setRoles: "roles/setRoles",
      setTypes: "enterprise/setTypes",
      setProvinces: "enterprise/setProvinces",
    }),
    deleteSelectedUser() {
      this.deleteUser(this.selectedUser.id);
      const position = this.users.indexOf(this.selectedUser);
      this.users.splice(position, 1);
      this.editingUser = false;
    },
    setEnterpriseValues() {
      this.enterprise = this.getEnterprise;
    },
    setUserValues() {
      this.users = this.getUsers;
    },
    setProvincesValues() {
      this.getProvinces.forEach((province) => {
        this.provinceList.push({
          value: province.id,
          name: province.name,
        });
      });
    },
    setTypesValues() {
      this.typeList = [];
      this.getTypes.forEach((type) => {
        this.typeList.push({
          value: type.id,
          name: type.name,
        });
      });
    },
    newUser() {
      this.users.push({
        id: this.idUser--,
        name: "",
        email: "",
        roles: [],
        new: true,
      });
      this.editingUser = true;
    },
    onDeleteUser(user) {
      this.selectedUser = user;
      this.showModal = true;
    },
    onSaveUser(user) {
      user.surnames = "";
      if (user.new) {
        this.$toasted.show(this.$t("configuracion.email"), {
          position: "bottom-right",
          duration: 5000,
        });
        const position = this.users.indexOf(user);
        this.users[position] = user;
      }
      this.editingUser = false;
    },
    onEditingUser() {
      this.editingUser = true;
    },
    onCancelUser(user) {
      if (user.new) {
        this.users.splice(0, 1);
      }
      this.editingUser = false;
      this.setUsers();
    },
    onSaveEnterprise() {
      if (this.enabledEnterprise) {
        this.updateEnterprise(this.enterprise);
      }
      this.enabledEnterprise = !this.enabledEnterprise;
    },
    onCancelEnterprise() {
      this.enabledEnterprise = !this.enabledEnterprise;
      this.setEnterprise();
    },
    refreshReq() {
      this.setRoles();
      this.setTypes().then(() => {
        this.typeList = [];
        this.getTypes.forEach((type) => {
          this.typeList.push({
            value: type.id,
            name: type.name,
          });
        });
      });
    },
  },
  filters: {
    reverse(items) {
      return items.slice().reverse();
    },
    firstCapital(val) {
      return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
    },
  },

  data() {
    return {
      name: "configuracion",
      users: [],
      userIcon,
      newUserIcon,
      enterpriseIcon,
      plusIcon,
      selectedType: null,
      idUser: 0,
      enabledEnterprise: false,
      enterprise: Object,
      neededEnterpriseRole: 2, //Gestion Empresa
      neededUserRole: 1, //Gestion Usuario,
      provinceList: [],
      typeList: [],
      editingUser: false,
      showModal: false,
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters({
      getUserEmail: "auth/userEmail",
      getUsers: "users/getUsers",
      getEnterprise: "enterprise/getEnterprise",
      getTypes: "enterprise/getTypes",
      getProvinces: "enterprise/getProvinces",
      getUserRoles: "auth/getRoles",
    }),
    editarGuardar() {
      return this.enabledEnterprise
        ? this.$t("common.guardar")
        : this.$t("common.editar");
    },
    divExpanded() {
      return this.enabledEnterprise ? "w-45" : "w-20";
    },
    hasEnterpriseRole() {
      if (this.getUserRoles) {
        return this.getUserRoles.includes(this.neededEnterpriseRole);
      } else {
        return false;
      }
    },
    hasUserRole() {
      if (this.getUserRoles) {
        return this.getUserRoles.includes(this.neededUserRole);
      } else {
        return false;
      }
    },
    styleSelectCustom() {
      return this.enabledEnterprise
        ? "text-grey-500 border-b mt-2 border-orange-400 text-sm pl-1 truncate"
        : "text-grey-500 border-b mt-2 border-grey-400 text-sm pl-1 truncate";
    },
    styleIconAddUser() {
      return this.editingUser
        ? "rounded-full text-gray-500 fill-current transition-duration-100"
        : "rounded-full text-orange-400 fill-current hover:text-orange-600 transition-duration-100";
    },
    listaTipos() {
      return this.typeList.length <= 0 ? [] : this.typeList;
    },
    listaProvincias() {
      return this.provinceList.length <= 0 ? [] : this.provinceList;
    },
    editable() {
      return !this.editingUser;
    },
  },
  validations: {
    enterprise: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      cif: {
        minLength: minLength(9),
        maxLength: maxLength(9),
        alphaNum,
      },
      town: {
        maxLength: maxLength(50),
      },
    },
  },
  watch: {
    getUsers(newUserValue) {
      if (newUserValue) {
        this.setUserValues();
      }
    },
    getEnterprise(newEnterpriseValue) {
      if (newEnterpriseValue) {
        this.setEnterpriseValues();
      }
    },
  },
  components: {
    vcHeader: Header,
    vcBox: Box,
    vcBoxUser: BoxUser,
    vcBtnSimple: BtnSimple,
    vcSectionSeparator: SectionSeparator,
    vcIcon: Icon,
    Modal,
    SelectCustom,
  },
};
</script>

<style></style>
