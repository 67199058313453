<template>
  <aside
    class="group z-100 absolute h-full bg-orange-600 overflow-hidden w-auto max-w-5 transition-duration-250 hover:max-w-xl"
  >
    <ul class="text-4xl flex flex-col h-full">
      <li class="p-3 pb-0">
        <div
          class="items-center justify-start overflow-hidden relative h-14 whitespace-nowrap"
        >
          <div
            class="fill-current text-white py-2 block absolute h-full w-auto px-2"
          >
            <vc-icon :icon="marcaCsirtIcon" :center="false"></vc-icon>
          </div>
        </div>
        <hr class="border-orange-400 mt-1 h-1" />
      </li>
      <li
        v-for="(icon, index) in icons"
        :key="index"
        class="px-2 focus:outline-none"
      >
        <div
          class="fill-current text-white py-1 block navItem focus:outline-none"
          :class="{ disabled: isDisabled(icon.name) }"
        >
          <router-link
            :to="`/${icon.name}`"
            class
            v-if="!isDisabled(icon.name)"
          >
            <div
              class="items-center overflow-hidden whitespace-nowrap focus:outline-none hover:bg-orange-400 transition-duration-100"
            >
              <div class="w-full h-16 px-4 focus:outline-none">
                <div
                  class="navLink h-full w-8 inline-block align-middle focus:outline-none"
                >
                  <vc-icon :icon="icon"></vc-icon>
                </div>
                <span
                  class="text-lg inline-block align-middle pl-5 focus:outline-none"
                  >{{ name(icon.name) | firstCapital }}</span
                >
              </div>
            </div>
          </router-link>

          <div
            class="items-center justify-start overflow-hidden whitespace-nowrap"
            v-if="isDisabled(icon.name)"
          >
            <div class="navLink h-14 block w-16 inline-block align-middle">
              <vc-icon :icon="icon"></vc-icon>
            </div>
            <span class="text-lg inline-block align-middle">{{
              name(icon.name) | firstCapital
            }}</span>
          </div>
        </div>
        <hr class="border-orange-400 mt-1 h-1" />
      </li>
    </ul>
  </aside>
</template>

<script>
import Icon from "./icon/Icon.vue";
import { logo, icons, marcaCsirtIcon } from "@/assets/data/icons.js";

export default {
  data() {
    return {
      logo,
      icons,
      marcaCsirtIcon,
      disable: ["dashboard"],
    };
  },
  filters: {
    firstCapital(val) {
      return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
    },
  },
  methods: {
    isDisabled(name) {
      return this.disable.indexOf(name) != -1;
    },
    name(value) {
      switch (value) {
        case "informes":
          return this.$t("informes.informes");
        case "configuracion":
          return this.$t("configuracion.configuracion");
        case "herramientas":
          return this.$t("herramientas.herramientas");
        case "tareas":
          return this.$t("tareas.tareas");
        default:
          return value;
      }
    },
  },
  components: {
    "vc-icon": Icon,
  },
};
</script>

<style></style>
