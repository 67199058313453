<template>
  <div id="herramientas" v-bar class="w-full vb ml-20">
    <div>
      <div class="wrapper">
        <vc-header
          :icon-name="name"
          :display-name="$t('herramientas.herramientas')"
          :email="getUserEmail"
        />
        <div class="flex flex-wrap">
          <div
            v-for="(herramienta, index) in herramientas"
            id="boxWrapper"
            :key="index"
            class="w-full md:w-1/2 lg:w-1/3"
          >
            <vcBox :icon="herramientasIcon" :enable="false">
              <div class="w-full">
                <div class="mb-4">
                  <label for="nombre" class="clearfix">{{ $t("common.nombre") | firstCapital }}:</label>
                  <div class="px-4">
                    <label
                      class="w-full mt-2 px-1 block border-b text-sm placeholder-orange-300 border-orange-400 truncate"
                    >{{
                        $t(`herramientas.descripciones.${herramienta.name}`)
                      }}</label>
                  </div>
                </div>
                <div class="mb-4">
                  <label class="clearfix">{{
                      herramienta.link
                        ? $t("common.enlace")
                        : $t("leyenda.leyenda")
                    }}:</label>
                  <div class="px-4">
                    <a
                      v-if="herramienta.link"
                      :href="herramienta.link"
                      target="_blank"
                      class="w-full mt-2 px-1 block border-b text-sm placeholder-orange-300 border-orange-400 text-orange-400 visited:text-orange-300 truncate"
                    >{{ herramienta.link | removeHttp }}</a>
                    <div
                      v-else
                      class="w-full mt-2 px-1 block border-b text-sm border-orange-400"
                    >
                      {{ $t(`herramientas.descripciones.${herramienta.text}`) }}
                    </div>
                  </div>
                </div>

                <div class="flex flex-col mb-4">
                  <label class="clearfix">{{ $t("common.descripcion") | firstCapital }}:</label>
                  <div class="px-4 h-24 mt-2">
                    <div
                      v-bar
                      class="p-1 pr-0 w-full h-full block border text-sm placeholder-orange-300 border-orange-400 vb rounded"
                    >
                      <div>
                        <div class="pr-3">
                          {{
                            $t(`herramientas.descripciones.${herramienta.desc}`)
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vcBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { icons } from "@/assets/data/icons.js";
import Box from "@/components/Box.vue";
import Header from "@/components/Header.vue";

export default {
  name: "Herramientas",
  data() {
    return {
      name: "herramientas",
      herramientas: [
        {
          name: "malwarebytesName",
          link: "https://malwarebytes.com/",
          desc: "malwarebytesDes",
        },
        {
          name: "virustotalName",
          link: "https://www.virustotal.com/",
          desc: "virustotalDes",
        },
        {
          name: "browserauditName",
          link: "https://browseraudit.com/",
          desc: "browserauditDes",
        },
        {
          name: "browserleaksName",
          link: "https://browserleaks.com/",
          desc: "browserleaksDes",
        },
        {
          name: "keepassName",
          link: "https://keepass.info",
          desc: "keepassDes",
        },
        {
          name: "veracryptName",
          link: "https://www.veracrypt.fr",
          desc: "veracryptDes",
        },
        {
          name: "sslservertestName",
          link: "https://www.ssllabs.com/ssltest",
          desc: "sslservertestDes",
        },
        {
          name: "whoisName",
          link: "https://lookup.icann.org/",
          desc: "whoisDes",
        },
        {
          name: "whoislineadecomandoName",
          text: "whoislineadecomandoText",
          desc: "whoislineadecomandoDes",
        },
        {
          name: "dnslookupName",
          link: "https://dnslookup.es/",
          desc: "dnslookupDes",
        },
        {
          name: "diagnosticowebsdegoogleName",
          link: "https://transparencyreport.google.com/safe-browsing/search",
          desc: "diagnosticowebsdegoogleDes",
        },
        {
          name: "abuseipdbName",
          link: "https://www.abuseipdb.com/",
          desc: "abuseipdbDes",
        },
        {
          name: "mxtoolboxName",
          link: "https://mxtoolbox.com/",
          desc: "mxtoolboxDes",
        },
        {
          name: "googlealertsName",
          link: "https://www.google.es/alerts",
          desc: "googlealertsDes",
        },
        {
          name: "pastebinName",
          link: "https://pastebin.com/",
          desc: "pastebinDes",
        },
        {
          name: "haveibeenpwnedName",
          link: "https://haveibeenpwned.com/",
          desc: "haveibeenpwnedDes",
        },
        {
          name: "googleplayName",
          link: "https://play.google.com",
          desc: "googleplayDes",
        },
        {
          name: "appstoreName",
          link: "https://www.apple.com/es/ios/app-store/",
          desc: "appstoreDes",
        },
        {
          name: "shodanName",
          link: "https://www.shodan.io/",
          desc: "shodanDes",
        },
        {
          name: "focaName",
          link: "https://cybersecuritycloud.telefonicatech.com/innovacion-labs/tecnologias-innovacion/foca",
          desc: "focaDes",
        },
        {
          name: "boletinoficialdelestadoName",
          link: "https://www.boe.es/",
          desc: "boletinoficialdelestadoDes",
        },
        {
          name: "librebormeName",
          link: "https://librebor.me/",
          desc: "librebormeDes",
        },
        {
          name: "registromercantilName",
          link: "https://sede.registradores.org/site/mercantil",
          desc: "registromercantilDes",
        },
        {
          name: "plataformadecontrataciondelsectorpublicoName",
          link: "https://contrataciondelestado.es/wps/portal/plataforma",
          desc: "plataformadecontrataciondelsectorpublicoDes",
        },
        {
          name: "wikipediaName",
          link: "https://www.wikipedia.org/",
          desc: "wikipediaDes",
        },
        {
          name: "visorcartograficodezonasinundablesName",
          link: "https://sig.mapama.gob.es/geoportal/",
          desc: "visorcartograficodezonasinundablesDes",
        },
        {
          name: "mapasdesismicidadypeligrosidadName",
          link: "http://www.ign.es/web/ign/portal/mapas-sismicidad",
          desc: "mapasdesismicidadypeligrosidadDes",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getUserEmail: "auth/userEmail",
    }),
    herramientasIcon() {
      return icons.find((icon) => icon.name === "herramientas");
    },
  },

  components: {
    "vc-header": Header,
    vcBox: Box,
  },
  filters: {
    removeHttp(value) {
      return value.replace("https://", "").replace("http://", "");
    },
    firstCapital(val) {
      return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
    },
  },
};
</script>

<style></style>
