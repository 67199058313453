<template>
  <button
    class="focus:outline-none"
    @click="$emit('click')"
    :disabled="disabled || invalid"
    :class="clase"
  >{{name}}</button>
</template>

<script>
export default {
  props: {
    name: String,
    styleWrap: String,
    disableStyle: String,
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    clase() {
      return this.disabled || this.invalid ? this.disableStyle : this.styleWrap;
    }
  }
};
</script>
