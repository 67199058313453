<template>
  <div class="mt-10 comment" :class="{ 'show-comment': toggled }">
    <div
      class="flag flex text-right cursor-pointer justify-start items-center px-4"
    >
      <div class="flex flex-col flex-grow">
        <div
          class="text-xs text-gray-500 self-end border-b border-gray-30 w-full flex justify-end"
          @click="toggle"
        >
          {{ $t("test.anyadirComentario") }}:
          <div
            class="ml-2 mt-1 w-2 h-2 rotate text-orange-600 fill-current -rotate-90 transition-duration-250 arrow"
          >
            <vc-icon :icon="arrowIcon"></vc-icon>
          </div>
        </div>
        <div
          class="overflow-hidden h-0 box-comment w-full border-gray-600 rounded"
          :class="{ 'border ': toggled }"
        >
          <div class="w-full vb" v-bar>
            <textarea
              class="bg-gray-200 placeholder-gray-600 focus:outline-none resize-none w-full px-4 py-2"
              :placeholder="$t('testDashboard.notas')"
              v-model="inputValue"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon.vue";
import { arrowIcon } from "@/assets/data/icons.js";
export default {
  data() {
    return {
      toggled: false,
      arrowIcon,
      inputValue: this.value,
    };
  },
  props: {
    value: String,
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("input", value);
    },
  },
  components: {
    vcIcon: Icon,
  },
};
</script>
